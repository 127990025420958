import * as React from "react";
import useBreakpoints from "../../hooks/useBreakpoints.ts";
import { NotFoundMobileAndTablet } from "./not-found.tsx";
import { NotFoundDesktop } from "./not-found-desktop.tsx";
import { Flex } from "antd";
import Header from "../header/index.tsx";
import { Link, ScrollRestoration } from "react-router-dom";
import Footer from "../footer/index.tsx";
import Typography from "../typography/index.tsx";
import MainButton from "../main/MainButton.tsx";

const NotFound = () => {
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);
  return (
    <>
      <Header />
      <Flex
        align="center"
        justify="center"
        vertical
        style={{ height: "640px" }}
        gap={"32px"}
      >
        {isMobile ? <NotFoundMobileAndTablet /> : <NotFoundDesktop />}
        <Typography variant="h3">
          {"Упс... Сторінку не знайдено".toUpperCase()}
        </Typography>
        <Link to={"/"}>
          <MainButton text={"На головну сторінку".toUpperCase()} />
        </Link>
      </Flex>

      <Footer />
      <ScrollRestoration />
    </>
  );
};

export default NotFound;
