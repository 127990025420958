import { Col, Flex, Image, Row } from "antd";
import * as React from "react";
import { Link, useNavigation } from "react-router-dom";
import Loading from "../components/loader/Loading.tsx";
import Typography from "../components/typography/index.tsx";
import { Collection, shopService } from "../services/shopService.ts";
import ProductPreview from "./products/ProductPreview.tsx";
import useBreakpoints from "../hooks/useBreakpoints.ts";
import MainMobile from "./mobile/MainMobile.tsx";
import MainButton from "../components/main/MainButton.tsx";

export const mainLoader = async (): Promise<Collection[] | undefined> => {
  const product = (await shopService.getCollections()) as Collection[];
  return product;
};

const Main = () => {
  const [collections, setCollections] = React.useState<Collection[] | undefined>();
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);

  React.useEffect(() => {
    shopService
      .getCollections()
      .then(setCollections)
      .catch((e) => console.error(e));
  }, []);

  if (isMobile && collections) return <MainMobile collections={collections}/>
  return (
    <Flex justify="center">
      {collections ? (
        collections.map(
          (c) =>
            c.products != undefined &&
            c.products.length > 0 && (
              <Flex key={c.collectionId} vertical>
                <Flex style={{ position: "relative", margin: "0 8px" }}>
                  <div style={{ position: "absolute", zIndex: 2, top: "24px", left: "24px" }}>
                    <Flex vertical gap="12px">
                      <Typography variant="h3">{c.name.toUpperCase()}</Typography>
                      <Link to={`collections/${c.collectionId}`}>
                        <MainButton
                          text={"Переглянути".toUpperCase()}
                          style={{ paddingBottom: "8px", borderBottom: "1px solid #9FB3D6" }}

                        />
                      </Link>
                    </Flex>
                  </div>
                  <Image src={c.imageLink} preview={false} width={"100%"} />
                </Flex>
                <Row>
                  {c.products.map(
                    (p) =>
                      p.productImages &&
                      p.productImages.length > 0 && (
                        <Col span={6}>
                          <Link to={`products/${p.productId}`}>
                            <ProductPreview key={p.productId} product={p} />
                          </Link>
                        </Col>
                      )
                  )}
                </Row>
              </Flex>
            )
        )
      ) : (
        <Loading size={"126px"} />
      )}
    </Flex>
  );
};

export default Main;
