import React from "react";
export const NotFoundMobileAndTablet = () => (
  <svg
    width="328"
    height="174"
    viewBox="0 0 328 174"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M85.9327 109.3H95.57V116.947H76.2954V143.353H68.2643V136.751H60.2332V130.15V116.947H30.1166H15.0583H7.52915V108.575H0V100.203L52.2021 30.6475H64.2488H70.2721V39.523H76.2954V48.3985V66.1496V101.652H85.9327V109.3ZM15.5803 101.652H60.2332V42.079L15.5803 101.652Z"
      fill="#767D93"
    />
    <path
      d="M318.362 109.3H328V116.947H308.725V143.353H300.694V136.751H292.663V130.15V116.947H262.546H247.488H239.959V108.575H232.43V100.203L284.632 30.6475H296.678H302.702V39.523H308.725V48.3985V66.1496V101.652H318.362V109.3ZM248.01 101.652H292.663V42.079L248.01 101.652Z"
      fill="#767D93"
    />
    <path d="M170.81 73.0848H157.199V82.008H170.81V73.0848Z" fill="#767D93" />
    <path d="M170.81 46.0154H157.199V54.9386H170.81V46.0154Z" fill="#767D93" />
    <path
      d="M177.615 37.0922H164.004V46.0154H177.615V37.0922Z"
      fill="#767D93"
    />
    <path
      d="M184.422 28.1804H170.811V37.1037H184.422V28.1804Z"
      fill="#767D93"
    />
    <path
      d="M191.226 19.2572H177.615V28.1805H191.226V19.2572Z"
      fill="#767D93"
    />
    <path d="M198.033 10.334H184.422V19.2572H198.033V10.334Z" fill="#767D93" />
    <path d="M157.187 73.0848H143.576V82.008H157.187V73.0848Z" fill="#767D93" />
    <path d="M144.787 73.0848H131.176V82.008H144.787V73.0848Z" fill="#767D93" />
    <path d="M184.422 73.0848H170.811V82.008H184.422V73.0848Z" fill="#767D93" />
    <path d="M198.033 73.0848H184.422V82.008H198.033V73.0848Z" fill="#767D93" />
    <path
      d="M204.838 65.0493H191.227V73.9725H204.838V65.0493Z"
      fill="#767D93"
    />
    <path d="M136.771 65.0493H123.16V73.9725H136.771V65.0493Z" fill="#767D93" />
    <path
      d="M211.644 56.1376H198.033V65.0609H211.644V56.1376Z"
      fill="#767D93"
    />
    <path d="M218.101 47.2144H204.49V56.1376H218.101V47.2144Z" fill="#767D93" />
    <path
      d="M224.908 38.2911H211.297V47.2144H224.908V38.2911Z"
      fill="#767D93"
    />
    <path
      d="M231.713 29.3679H218.102V38.2912H231.713V29.3679Z"
      fill="#767D93"
    />
    <path
      d="M163.992 37.0922H150.381V46.0154H163.992V37.0922Z"
      fill="#767D93"
    />
    <path
      d="M157.187 28.1804H143.576V37.1037H157.187V28.1804Z"
      fill="#767D93"
    />
    <path d="M150.381 19.2572H136.77V28.1805H150.381V19.2572Z" fill="#767D93" />
    <path d="M143.578 10.334H129.967V19.2572H143.578V10.334Z" fill="#767D93" />
    <path
      d="M129.965 56.1376H116.354V65.0609H129.965V56.1376Z"
      fill="#767D93"
    />
    <path
      d="M123.504 47.2144H109.893V56.1376H123.504V47.2144Z"
      fill="#767D93"
    />
    <path d="M116.701 38.2911H103.09V47.2144H116.701V38.2911Z" fill="#767D93" />
    <path
      d="M109.894 29.3679H96.2832V38.2912H109.894V29.3679Z"
      fill="#767D93"
    />
    <path d="M169.703 0.5H158.307V10.4954H169.703V0.5Z" fill="#767D93" />
    <path d="M170.81 91.9919H157.199V100.915H170.81V91.9919Z" fill="#767D93" />
    <path d="M170.81 119.061H157.199V127.985H170.81V119.061Z" fill="#767D93" />
    <path
      d="M177.615 127.985H164.004V136.908H177.615V127.985Z"
      fill="#767D93"
    />
    <path
      d="M184.422 136.908H170.811V145.831H184.422V136.908Z"
      fill="#767D93"
    />
    <path
      d="M191.226 145.831H177.615V154.754H191.226V145.831Z"
      fill="#767D93"
    />
    <path
      d="M198.033 154.743H184.422V163.666H198.033V154.743Z"
      fill="#767D93"
    />
    <path
      d="M157.187 91.9919H143.576V100.915H157.187V91.9919Z"
      fill="#767D93"
    />
    <path
      d="M144.787 91.9919H131.176V100.915H144.787V91.9919Z"
      fill="#767D93"
    />
    <path
      d="M184.422 91.9919H170.811V100.915H184.422V91.9919Z"
      fill="#767D93"
    />
    <path
      d="M198.033 91.9919H184.422V100.915H198.033V91.9919Z"
      fill="#767D93"
    />
    <path
      d="M204.838 100.027H191.227V108.951H204.838V100.027Z"
      fill="#767D93"
    />
    <path d="M136.771 100.027H123.16V108.951H136.771V100.027Z" fill="#767D93" />
    <path
      d="M211.644 108.951H198.033V117.874H211.644V108.951Z"
      fill="#767D93"
    />
    <path d="M218.101 117.862H204.49V126.786H218.101V117.862Z" fill="#767D93" />
    <path
      d="M224.908 126.786H211.297V135.709H224.908V126.786Z"
      fill="#767D93"
    />
    <path
      d="M231.713 135.709H218.102V144.632H231.713V135.709Z"
      fill="#767D93"
    />
    <path
      d="M163.992 127.985H150.381V136.908H163.992V127.985Z"
      fill="#767D93"
    />
    <path
      d="M157.187 136.908H143.576V145.831H157.187V136.908Z"
      fill="#767D93"
    />
    <path d="M150.381 145.831H136.77V154.754H150.381V145.831Z" fill="#767D93" />
    <path
      d="M143.578 154.743H129.967V163.666H143.578V154.743Z"
      fill="#767D93"
    />
    <path
      d="M129.965 108.951H116.354V117.874H129.965V108.951Z"
      fill="#767D93"
    />
    <path
      d="M123.504 117.862H109.893V126.786H123.504V117.862Z"
      fill="#767D93"
    />
    <path d="M116.701 126.786H103.09V135.709H116.701V126.786Z" fill="#767D93" />
    <path
      d="M109.894 135.709H96.2832V144.632H109.894V135.709Z"
      fill="#767D93"
    />
    <path d="M169.703 163.505H158.307V173.5H169.703V163.505Z" fill="#767D93" />
    <path
      d="M232.428 82.1349H221.031V92.1303H232.428V82.1349Z"
      fill="#767D93"
    />
    <path
      d="M106.965 82.1349H95.5684V92.1303H106.965V82.1349Z"
      fill="#767D93"
    />
    <path
      d="M169.703 82.0081H158.307V92.0035H169.703V82.0081Z"
      fill="#767D93"
    />
  </svg>
);
