import { Flex, Row } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";
import { OrderContext } from "../../../App.tsx";
import {
  Currency,
  Order,
  OrderItem,
  Product,
  shopService,
} from "../../../services/shopService.ts";
import Loading from "../../loader/Loading.tsx";
import MainButton from "../../main/MainButton.tsx";
import Typography from "../../typography/index.tsx";
import CartProductPreview from "./CartProductPreview.tsx";

interface CartPreviewInterface {
  hide?: () => void;
  order: Order;
  products: Product[];
  orderCount: OrderItem[];
}

const CartPreview = ({
  hide,
  order,
  products,
  orderCount,
}: CartPreviewInterface) => {
  console.log(orderCount);

  const findProduct = (orderItem: OrderItem): number => {
    const product = products.find((p) => p.productId === orderItem.productId);
    return product ? product.price : 0;
  };

  // if (orderCount.length === 0) return <>{hide && hide()}</>
  return orderCount?.length >= 0 && products?.length >= 0 ? (
    <Flex
      vertical
      style={{ width: "328px", maxHeight: "700px", overflowY: "auto" }}
    >
      {orderCount.map((item) => {
        const product = products.find((p) => p.productId === item.productId);
        if (product)
          return (
            <CartProductPreview
              key={item.productId}
              product={product}
              orderItems={order.orderItems!!}
            />
          );
      })}
      <Row justify={"space-between"} style={{ paddingBottom: "16px" }}>
        <Typography variant="uppercase">{"підсумок:"}</Typography>
        <Typography style={{ float: "right" }}>
          {orderCount.length > 0 && products.length > 0
            ? Currency[products[0].currencyId]
            : ""}
          {orderCount.reduce((sum, item) => sum + findProduct(item), 0)}
        </Typography>
      </Row>
      <Row>
        <Link to={"/order"}>
          <MainButton
            onClick={hide}
            text={"Перейти до оформлення".toUpperCase()}
            disabled={orderCount.length === 0}
          />
        </Link>
      </Row>
    </Flex>
  ) : (
    <Flex style={{ width: "328px", justifyContent: "center" }}>
      <Loading size={"56px"} />
    </Flex>
  );
};

export default CartPreview;
