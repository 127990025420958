import React from "react";
const Payment = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8569 19.3571C20.6296 19.3571 20.4115 19.4474 20.2508 19.6082C20.0901 19.7689 19.9998 19.987 19.9998 20.2143C19.9998 20.4416 20.0901 20.6596 20.2508 20.8204C20.4115 20.9811 20.6296 21.0714 20.8569 21.0714H24.8569C25.0842 21.0714 25.3022 20.9811 25.463 20.8204C25.6237 20.6596 25.714 20.4416 25.714 20.2143C25.714 19.987 25.6237 19.7689 25.463 19.6082C25.3022 19.4474 25.0842 19.3571 24.8569 19.3571H20.8569ZM2.29004 10.5C2.29004 9.36336 2.74157 8.27327 3.5453 7.46954C4.34902 6.66582 5.43911 6.21429 6.57575 6.21429H25.4283C26.565 6.21429 27.6551 6.66582 28.4588 7.46954C29.2625 8.27327 29.714 9.36336 29.714 10.5V22.5C29.714 23.6366 29.2625 24.7267 28.4588 25.5305C27.6551 26.3342 26.565 26.7857 25.4283 26.7857H6.5769C5.44025 26.7857 4.35017 26.3342 3.54644 25.5305C2.74271 24.7267 2.29118 23.6366 2.29118 22.5L2.29004 10.5ZM6.57575 7.92857C5.89377 7.92857 5.23971 8.19949 4.75748 8.68173C4.27524 9.16396 4.00432 9.81802 4.00432 10.5V11.3571H27.9998V10.5C27.9998 9.81802 27.7288 9.16396 27.2466 8.68173C26.7644 8.19949 26.1103 7.92857 25.4283 7.92857H6.57575ZM4.00432 22.5C4.00432 23.182 4.27524 23.836 4.75748 24.3183C5.23971 24.8005 5.89377 25.0714 6.57575 25.0714H25.4283C26.1103 25.0714 26.7644 24.8005 27.2466 24.3183C27.7288 23.836 27.9998 23.182 27.9998 22.5V13.0714H4.00547L4.00432 22.5Z"
        fill="#9FB3D6"
      />
    </svg>
  );
};
export default Payment;
