import * as React from "react";
import styled from "styled-components";

const HeaderButtonContainer = styled.p`
  color: black;
  &:hover {
    color: #767d93;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const HeaderButton = ({ text }) => {
  return <HeaderButtonContainer>{text}</HeaderButtonContainer>;
};

export default HeaderButton;
