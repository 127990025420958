import { Col, Divider, Flex, Row } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import CartProductPreview from "../../components/cart/preview/CartProductPreview.tsx";
import ContactForm from "../../components/checkout/ContactForm.tsx";
import MainButton from "../../components/main/MainButton.tsx";
import {
  Currency,
  Order,
  OrderItem,
  Product,
} from "../../services/shopService.ts";
import Typography from "../../components/typography/index.tsx";

interface MobileCheckoutViewInterface {
  order: Order;
  products: Product[];
  totalPrice: number;
  orderItems;
}

const MobileCheckoutView = ({
  order,
  products,
  totalPrice,
  orderItems,
}: MobileCheckoutViewInterface) => {
  const navigate = useNavigate();

  const findProduct = (orderItem: OrderItem): Product => {
    return products.find((p) => p.productId === orderItem.productId)!!;
  };

  return (
    <Col>
      <Row style={{ background: "#FCFCFC" }}>
        <Row
          justify={"space-between"}
          align={"middle"}
          style={{ padding: "16px" }}
        >
          <p style={{ fontSize: "18px", margin: 0 }}>
            {`Оформлення замовлення`.toUpperCase()}
          </p>
          <MainButton
            text={"Продовжити покупку".toUpperCase()}
            onClick={() => navigate(-2)}
          />
        </Row>
        <ContactForm price={totalPrice} />
      </Row>
      <Row style={{ padding: "16px", background: "#FFFFFF" }}>
        <Flex vertical style={{ width: "100%" }}>
          {orderItems.map((item) => (
            <CartProductPreview
              product={products.find((p) => p.productId === item.productId)!!}
              orderItems={order.orderItems}
              spans={[8, 16]}
            />
          ))}
        </Flex>
        <Row
          justify={"space-between"}
          style={{ paddingBottom: "16px", width: "100%" }}
        >
          <Typography variant="h5">{"ПРОМІЖНИЙ ПІДСУМОК:"}</Typography>
          <Typography variant="h5" style={{ float: "right" }}>
            {products.length > 0 ? Currency[products[0].currencyId] : ""}
            {orderItems.reduce((sum, item) => sum + findProduct(item).price, 0)}
          </Typography>
        </Row>
        <Row justify={"space-between"} style={{ width: "100%" }}>
          <Typography variant="h5">{"ДОСТАВКА:"}</Typography>
          <Typography variant="h5" style={{ float: "right" }}>
            {"За тарифами перевізника".toUpperCase()}
          </Typography>
        </Row>
        <Divider style={{ background: "#D0D4DD" }} />
        <Row style={{ paddingBottom: "16px", width: '100%' }} justify={"space-between"}>
          <Typography variant="h5">
            {"підсумок:".toUpperCase()}
          </Typography>
          <Typography variant="h5" style={{ float: "right" }}>
            {products.length > 0 ? Currency[products[0].currencyId] : ""}
            {totalPrice}
          </Typography>
        </Row>
      </Row>
    </Col>
  );
};

export default MobileCheckoutView;
