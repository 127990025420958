import { Button, Flex } from "antd";
import * as React from "react";
import Arrow from "../Icons/Arrow.tsx";
import styledComponents from "styled-components";
import Typography from "../typography/index.tsx";

export const ButtonFlex = styledComponents((props) => <Flex {...props} />)`
  align-items: center; 
  color: #767D93;
  gap: 8px;
  padding-bottom: 5px; 
  &:hover {
    border-bottom: 1px solid #767D93;
    path {
      stroke: #767D93;
    }
  };  
`;

const ButtonWithArrow = ({ text, onClick }) => {
  return (
    <Button type="text" style={{ padding: 0, justifyContent: "left", width: 'fit-content' }} onClick={onClick}>
      <ButtonFlex>
        <Typography>{text}</Typography>
        <Arrow />
      </ButtonFlex>
    </Button>
  );
};

export default ButtonWithArrow;
