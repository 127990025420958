import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import CookieProvider from "./providers/CookieProvider.tsx";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <CookieProvider>
    <App />
  </CookieProvider>
);
