import React from "react";

const MadjoIcon = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.64639 6.71303H6.35352V7.5383H7.64639V6.71303Z" fill="#D0D4DD" />
      <path d="M7.64639 4.20949H6.35352V5.03476H7.64639V4.20949Z" fill="#D0D4DD" />
      <path d="M8.29288 3.38425H7V4.20952H8.29288V3.38425Z" fill="#D0D4DD" />
      <path d="M8.93936 2.56004H7.64648V3.3853H8.93936V2.56004Z" fill="#D0D4DD" />
      <path d="M9.58487 1.73479H8.29199V2.56006H9.58487V1.73479Z" fill="#D0D4DD" />
      <path d="M10.2323 0.909492H8.93945V1.73476H10.2323V0.909492Z" fill="#D0D4DD" />
      <path d="M6.35343 6.71303H5.06055V7.5383H6.35343V6.71303Z" fill="#D0D4DD" />
      <path d="M5.17569 6.71303H3.88281V7.5383H5.17569V6.71303Z" fill="#D0D4DD" />
      <path d="M8.93936 6.71303H7.64648V7.5383H8.93936V6.71303Z" fill="#D0D4DD" />
      <path d="M10.2323 6.71303H8.93945V7.5383H10.2323V6.71303Z" fill="#D0D4DD" />
      <path d="M10.8788 5.96989H9.58594V6.79516H10.8788V5.96989Z" fill="#D0D4DD" />
      <path d="M4.41397 5.96989H3.12109V6.79516H4.41397V5.96989Z" fill="#D0D4DD" />
      <path d="M11.5253 5.14573H10.2324V5.971H11.5253V5.14573Z" fill="#D0D4DD" />
      <path d="M12.1386 4.32043H10.8457V5.1457H12.1386V4.32043Z" fill="#D0D4DD" />
      <path d="M12.7851 3.49519H11.4922V4.32046H12.7851V3.49519Z" fill="#D0D4DD" />
      <path d="M13.4316 2.66989H12.1387V3.49516H13.4316V2.66989Z" fill="#D0D4DD" />
      <path d="M6.99991 3.38425H5.70703V4.20952H6.99991V3.38425Z" fill="#D0D4DD" />
      <path d="M6.35343 2.56004H5.06055V3.3853H6.35343V2.56004Z" fill="#D0D4DD" />
      <path d="M5.70694 1.73479H4.41406V2.56006H5.70694V1.73479Z" fill="#D0D4DD" />
      <path d="M5.06046 0.909492H3.76758V1.73476H5.06046V0.909492Z" fill="#D0D4DD" />
      <path d="M3.76749 5.14573H2.47461V5.971H3.76749V5.14573Z" fill="#D0D4DD" />
      <path d="M3.15421 4.32043H1.86133V5.1457H3.15421V4.32043Z" fill="#D0D4DD" />
      <path d="M2.50772 3.49519H1.21484V4.32046H2.50772V3.49519Z" fill="#D0D4DD" />
      <path d="M1.86124 2.66989H0.568359V3.49516H1.86124V2.66989Z" fill="#D0D4DD" />
      <path d="M7.54052 0H6.45801V0.924429H7.54052V0Z" fill="#D0D4DD" />
      <path d="M7.64639 8.46165H6.35352V9.28692H7.64639V8.46165Z" fill="#D0D4DD" />
      <path d="M7.64639 10.9652H6.35352V11.7905H7.64639V10.9652Z" fill="#D0D4DD" />
      <path d="M8.29288 11.7905H7V12.6158H8.29288V11.7905Z" fill="#D0D4DD" />
      <path d="M8.93936 12.6158H7.64648V13.4411H8.93936V12.6158Z" fill="#D0D4DD" />
      <path d="M9.58487 13.441H8.29199V14.2663H9.58487V13.441Z" fill="#D0D4DD" />
      <path d="M10.2323 14.2652H8.93945V15.0905H10.2323V14.2652Z" fill="#D0D4DD" />
      <path d="M6.35343 8.46165H5.06055V9.28692H6.35343V8.46165Z" fill="#D0D4DD" />
      <path d="M5.17569 8.46165H3.88281V9.28692H5.17569V8.46165Z" fill="#D0D4DD" />
      <path d="M8.93936 8.46165H7.64648V9.28692H8.93936V8.46165Z" fill="#D0D4DD" />
      <path d="M10.2323 8.46165H8.93945V9.28692H10.2323V8.46165Z" fill="#D0D4DD" />
      <path d="M10.8788 9.2048H9.58594V10.0301H10.8788V9.2048Z" fill="#D0D4DD" />
      <path d="M4.41397 9.2048H3.12109V10.0301H4.41397V9.2048Z" fill="#D0D4DD" />
      <path d="M11.5253 10.0301H10.2324V10.8554H11.5253V10.0301Z" fill="#D0D4DD" />
      <path d="M12.1386 10.8543H10.8457V11.6796H12.1386V10.8543Z" fill="#D0D4DD" />
      <path d="M12.7851 11.6796H11.4922V12.5048H12.7851V11.6796Z" fill="#D0D4DD" />
      <path d="M13.4316 12.5049H12.1387V13.3301H13.4316V12.5049Z" fill="#D0D4DD" />
      <path d="M6.99991 11.7905H5.70703V12.6158H6.99991V11.7905Z" fill="#D0D4DD" />
      <path d="M6.35343 12.6158H5.06055V13.4411H6.35343V12.6158Z" fill="#D0D4DD" />
      <path d="M5.70694 13.441H4.41406V14.2663H5.70694V13.441Z" fill="#D0D4DD" />
      <path d="M5.06046 14.2652H3.76758V15.0905H5.06046V14.2652Z" fill="#D0D4DD" />
      <path d="M3.76749 10.0301H2.47461V10.8554H3.76749V10.0301Z" fill="#D0D4DD" />
      <path d="M3.15421 10.8543H1.86133V11.6796H3.15421V10.8543Z" fill="#D0D4DD" />
      <path d="M2.50772 11.6796H1.21484V12.5048H2.50772V11.6796Z" fill="#D0D4DD" />
      <path d="M1.86124 12.5049H0.568359V13.3301H1.86124V12.5049Z" fill="#D0D4DD" />
      <path d="M7.54052 15.0756H6.45801V16H7.54052V15.0756Z" fill="#D0D4DD" />
      <path d="M13.5005 7.55005H12.418V8.47448H13.5005V7.55005Z" fill="#D0D4DD" />
      <path d="M1.58251 7.55005H0.5V8.47448H1.58251V7.55005Z" fill="#D0D4DD" />
      <path d="M7.54052 7.53833H6.45801V8.46276H7.54052V7.53833Z" fill="#D0D4DD" />
    </svg>
  );
};
export default MadjoIcon;
