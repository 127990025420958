import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Divider, Drawer, Flex, Input } from "antd";
import * as React from "react";
import { useLocation } from "react-router";
import { ProductSearch } from "../../../App.tsx";
import Typography from "../../typography/index.tsx";
import { InputStyled } from "../../utils/utils.tsx";

const Search = () => {
  const [open, setOpen] = React.useState(false);
  const { search, setSearch } = React.useContext(ProductSearch);
  const location = useLocation();
  
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div style={{cursor: location.pathname.startsWith('/collections/') ? 'auto' : 'not-allowed'}}>
      <InputStyled
        placeholder="ПОШУК"
        variant="borderless"
        prefix={<SearchOutlined />}
        style={{ color: "black", width: "87px", padding: 0 }}
        disabled={!location.pathname.startsWith('/collections/')}
        maxLength={15}
        onClick={showDrawer}
      />
      <Drawer
        onClose={onClose}
        open={open}
        height={"80px"}
        closable={false}
        placement="top"
        extra={<p>extra</p>}
      >
        <Flex align="center">
          <SearchOutlined />
          <Input
            placeholder="ПОШУК ПО MADJO.COM"
            variant="borderless"
            suffix={<CloseOutlined onClick={() => setSearch("")} />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              color: "black",
            }}
            maxLength={15}
            onClick={showDrawer}
          />
          <Typography onClick={onClose} variant="uppercase">
            CLOSE
          </Typography>
        </Flex>
        <Divider style={{ margin: 0, backgroundColor: "#767D93" }} />
      </Drawer>
    </div>
  );
};

export default Search;
