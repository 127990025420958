import * as React from "react";

const RadioButton = ({ isActive }) => {
  return isActive ? (
    <div style={{ cursor: "pointer" }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white" />
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="7.5"
          stroke="#767D93"
        />
        <circle cx="8" cy="8" r="5" fill="#767D93" />
      </svg>
    </div>
  ) : (
    <div style={{ cursor: "pointer" }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white" />
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="7.5"
          stroke="#767D93"
        />
      </svg>
    </div>
  );
};

export default RadioButton;
