import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HeaderButton from "./HeaderButton.tsx";
import Search from "./icons/Search.tsx";

const List = styled.ol<{ show: boolean }>`
  overflow: hidden;
  transform: ${(props) => (props.show ? "scaleY(1)" : "scaleY(0)")};
  opacity: ${(props) => (props.show ? "1" : "0")};
  transform-origin: top;
  transition: transform 0.75s ease-in-out, opacity 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 0.75rem;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 55px;
  z-index: 3;
  padding: ${(props) => (props.show ? "0 16px" : "0")};
  background-color: #fff;
  margin: 0;
  border-bottom: ${(props) => (props.show ? "1px solid #d0d4dd" : "none")};

  li:not(:last-child) {
    border-bottom: 1px solid #d0d4dd;
  }

  li {
    padding-top: ${(props) => (props.show ? "40px" : "0")};
    padding-bottom: ${(props) => (props.show ? "40px" : "0")};
    transition: padding 0.75s ease-in-out;
  }
`;


const NavMobile = ({ open, setOpen }) => {
  return (
    <List show={open}>
      <li>
        <Link to={"/men"} onClick={() => setOpen(false)}>
          <HeaderButton text={"ЧОЛОВIКАМ"} />
        </Link>
      </li>
      <li>
        <Link to={"/women"} onClick={() => setOpen(false)}>
          <HeaderButton text={"ЖIНКАМ"} />
        </Link>
      </li>
      <li>
          <Link to={"/autumn-2024"} onClick={() => setOpen(false)}>
                <HeaderButton text={"ОСІНЬ 2024"} />
          </Link>
      </li>
      <li>
          <Link to={"/spring-2025"} onClick={() => setOpen(false)}>
              <HeaderButton text={"ВЕСНА 2025"} />
          </Link>
      </li>
      <li>
        <Link to="/research" onClick={() => setOpen(false)}>
          <HeaderButton text={"Дослідження".toLocaleUpperCase()} />
        </Link>
      </li>
      {/* <li>
        <a href="#">
          <Search />
        </a>
      </li> */}
    </List>
  );
};

export default NavMobile;
