import React from "react";

const Arrow = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 14L11 8L5 2" stroke="#9FB3D6" />
    </svg>
  );
};
export default Arrow;
