import React from "react";
import useBreakpoints from "../../hooks/useBreakpoints.ts";

const Facebook = () => {
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);
  return isMobile ? (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.566 2.43311H6.43298C4.23298 2.43311 2.43298 4.23311 2.43298 6.43311V25.5681C2.43298 27.7681 4.23298 29.5681 6.43298 29.5681H25.568C27.768 29.5681 29.568 27.7681 29.568 25.5681V6.43311C29.566 4.23311 27.768 2.43311 25.566 2.43311ZM25.309 16.9161H22.089V28.5661H17.271V16.9161H14.861V12.9001H17.271V10.4901C17.271 7.21411 18.631 5.26511 22.501 5.26511H25.718V9.28011H23.706C22.202 9.28011 22.102 9.84311 22.102 10.8901L22.089 12.9001H25.734L25.309 16.9161Z"
        fill="#FCFCFC"
      />
    </svg>
  ) : (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7831 0.216309H2.21655C1.11655 0.216309 0.216553 1.11631 0.216553 2.21631V11.7838C0.216553 12.8838 1.11655 13.7838 2.21655 13.7838H11.7841C12.8841 13.7838 13.7841 12.8838 13.7841 11.7838V2.21631C13.7831 1.11631 12.8841 0.216309 11.7831 0.216309ZM11.6546 7.45781H10.0446V13.2828H7.63555V7.45781H6.43055V5.44981H7.63555V4.24481C7.63555 2.60681 8.31555 1.63231 10.2506 1.63231H11.8591V3.63981H10.8531C10.1011 3.63981 10.0511 3.92131 10.0511 4.44481L10.0446 5.44981H11.8671L11.6546 7.45781Z"
        fill="#FCFCFC"
      />
    </svg>
  );
};
export default Facebook;
