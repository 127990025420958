import { Col, Divider, Image, Row } from "antd";
import * as React from "react";
import styled from "styled-components";
import {
  Currency,
  OrderItem,
  Product
} from "../../../services/shopService.ts";
import DeleteButton from "../../buttons/DeleteButton.tsx";
import Typography from "../../typography/index.tsx";

interface CartProductPreviewInterface {
  product: Product;
  orderItems: OrderItem[] | undefined;
  spans?: number[];
}

const ProductPreviewContainer = styled.div`
  cursor: pointer;
  display: grid;
  height: 100%;
  max-width: 78px;
  box-sizing: border-box;
  align-items: center;
`;

const CartProductPreview = ({
  product,
  orderItems,
  spans = [8, 16],
}: CartProductPreviewInterface) => {
  const [productPreview, setProductPreview] = React.useState<string>();
  const [imageHovered, setImageHovered] = React.useState(false);
  const [orderId, setOrderId] = React.useState<number | null>();

  React.useEffect(() => {
    const id = localStorage.getItem("orderId");
    if (id) setOrderId(+id);
  }, []);

  React.useEffect(() => {
    const link = product.productImages.find((productImage) =>
      imageHovered ? productImage.is3dModel : productImage.isPrimary
    )?.link;
    setProductPreview(link);
  }, [imageHovered, product.productImages]);

  return ( 
    productPreview && (
      <>
        <Row>
          <Col span={spans[0]}>
            <ProductPreviewContainer
              onMouseOver={() => setImageHovered(true)}
              onMouseLeave={() => setImageHovered(false)}
            >
              <Image
                max-width={`78px`}
                src={productPreview}
                alt={product.description}
                preview={false}
              />
            </ProductPreviewContainer>
          </Col>
          <Col span={spans[1]} style={{ position: "relative" }}>
            <Row>
              <Typography variant="h5">{product.name}</Typography>
            </Row>
            <Row>
              <Typography variant="h5" style={{ color: "#767D93" }}>
                {Currency[product.currencyId]}
                {product.price}
              </Typography>
            </Row>
            <Row style={{ position: "absolute", bottom: 0 }}>
              <DeleteButton product={product} orderItems={orderItems!!} />
            </Row>
          </Col>
        </Row>
        <Row>
          <Divider style={{ background: "#D0D4DD" }} />
        </Row>
      </>
    )
  );
};

export default CartProductPreview;
