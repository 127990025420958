import sha1 from "crypto-js/sha1";
import CryptoJS from "crypto-js";
import { Buffer } from "buffer";
// @ts-ignore
window.Buffer = Buffer;

interface LiqPayParams {
  version?: number;
  action: string;
  amount: string;
  currency: string;
  customer_user_id: string;
  description: string;
  order_id: string;
  server_url: string;
  result_url: string;
}

export const generateLiqPaySignature = (
  publicKey: string,
  privateKey: string,
  params: LiqPayParams
): { data: string; signature: string } => {
  const json = JSON.stringify({
    version: 3,
    public_key: publicKey,
    ...params,
  });
  console.log(params);
  const data = Buffer.from(json).toString("base64");

  const signature = sha1(privateKey + data + privateKey).toString(
    CryptoJS.enc.Base64
  );

  return { data, signature };
};
