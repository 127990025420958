import { CloseOutlined } from "@ant-design/icons";
import { Divider, Drawer, Flex, Image, Segmented } from "antd";
import * as React from "react";
import ButtonWithArrow from "../../components/buttons/ButtonWithArrow.tsx";
import MainButton from "../../components/main/MainButton.tsx";
import Typography from "../../components/typography/index.tsx";
import { Currency, Product } from "../../services/shopService.ts";
import MadjoIcon from "../../components/Icons/MadjoIcon.tsx";

interface ProductViewMobile {
  product: Product;
  isSelected: boolean;
  order: boolean;
  addToOrder: () => void;
  setSizeId: React.Dispatch<React.SetStateAction<number | undefined>>;
  bodySize: string;
  setBodySize: React.Dispatch<string>;
  showDrawer: () => void;
  onClose: () => void;
  topProducts: Product[] | undefined;
  open: boolean;
}

const ProductViewMobile = ({
  product,
  isSelected,
  order,
  addToOrder,
  setSizeId,
  bodySize,
  setBodySize,
  showDrawer,
  onClose,
  topProducts,
  open,
}: ProductViewMobile) => {
  const [current, setCurrent] = React.useState(0);
  const elementRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    const handleScroll = (event) => {
      const elementsHight = [] as number[]
      if(elementRef.current){
        const el = [...elementRef.current.children]
        el.map((item) => {if(item instanceof HTMLElement) elementsHight.push(item.offsetTop)})
      }
      if(elementsHight.length > 0) {
        elementsHight.find((item, i) => {
          if (item > window.pageYOffset) {
            setCurrent(i - 1);
            return i;
          };
        })
      } 
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={{ paddingBottom: "2.5rem" }}>
      <Flex vertical>
        <Flex vertical={true} ref={elementRef}>
          {product.productImages.map((img, i) => (
            <Image
              id={img.link}
              width={"auto"}
              src={img.link}
              alt={product.description}
              preview={false}
              onTouchStart={() => setCurrent(i)}
            />
          ))}
          <div
            style={{
              position: "sticky",
              zIndex: 1,
              bottom: "50%",
              marginTop: `-${product.productImages.length * 17}px`,
            }}
          >
            {product.productImages.map((image, i) => (
              <div
                key={i}
                style={{
                  width: "6px",
                  height: "6px",
                  border: "1px solid #767d93",
                  background: current === i ? "#767d93" : "rgba(0, 0, 0, 0)",
                  margin: "8px",
                }}
                onClick={() => {
                  setCurrent(i);
                  document.getElementById(image.link)?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              />
            ))}
          </div>
        </Flex>
        <Flex vertical style={{ paddingLeft: "16px" }}>
          <p style={{ fontSize: "18px" }}>{product.name.toUpperCase()}</p>
          <Flex align="center">
            <Typography>Розмір:</Typography>
            <Segmented
              selected={isSelected}
              disabled={!isSelected}
              size="small"
              options={product.productSizes.map((s) => ({
                label: s.name,
                value: s,
                disabled: s.total === 0,
              }))}
              defaultValue={
                product.productSizes.filter((s) => s.total !== 0)[0]
              }
              onChange={function (newSize) {
                setBodySize(newSize.bodySize);
                return setSizeId(newSize.sizeId);
              }}
            />
             {bodySize}
          </Flex>
          <p style={{ fontSize: "32px", margin: "16px 0" }}>
            {Currency[product.currencyId]}
            {product.price}
          </p>
          <Flex vertical gap="40px">
            <MainButton
              text={"Купити".toUpperCase()}
              disabled={!order || !isSelected}
              onClick={addToOrder}
            />
            <ButtonWithArrow
              text={"Деталі про товар".toUpperCase()}
              onClick={showDrawer}
            />
          </Flex>
          <Drawer
            onClose={onClose}
            open={open}
            placement="bottom"
            style={{ position: "relative" }}
            closable={false}
          >
            <div style={{ float: "right" }}>
              <CloseOutlined onClick={onClose} />
            </div>
            <div>
              <Typography variant="h2" style={{ cursor: "auto" }}>
                Детальна інформація про продукт
              </Typography>
              <div style={{ padding: "8px 0" }} />
              <Typography variant="h3" style={{ cursor: "auto" }}>
                {product.description}
              </Typography>
              <Divider />
              <Typography variant="h3" style={{ cursor: "auto" }}>
                Матеріали
              </Typography>
              <ul>
                {product.materials.split(", ").map((material) => (
                  <li>
                    <Typography variant="h4">{material}</Typography>
                  </li>
                ))}
              </ul>
              <div style={{ position: 'fixed', bottom: '16px'}}>
                {Array.from({ length: 3 }, (_, index) => (
                  <MadjoIcon key={index} />
                ))}
              </div>
            </div>
          </Drawer>
        </Flex>
      </Flex>
    </div>
  );
};

export default ProductViewMobile;
