import * as React from "react";

const CheckboxGrey = ({ isActive }) => {
  return isActive ? (
    <div style={{ cursor: "pointer" }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="16" fill="#767D93" />
        <path d="M4 7L7 10L12 5" stroke="white" stroke-width="2" />
      </svg>
    </div>
  ) : (
    <div style={{ cursor: "pointer" }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="15" height="15" stroke="#767D93" />
      </svg>
    </div>
  );
};

export default CheckboxGrey;
