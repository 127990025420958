import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const useBreakpoints = () => {
  const [breakpoint, setBreakpoint] = useState<string>("");

  const isFullScreen = useMediaQuery({ query: "(max-width: 1440px)" });
  const isDesktopOrLaptop = useMediaQuery({ query: "(max-width: 1023px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    if (isMobile) setBreakpoint("xs");
    else if (isDesktopOrLaptop) setBreakpoint("md");
    else if (isFullScreen) setBreakpoint("lg");
    else setBreakpoint("xl");
  }, [isFullScreen, isDesktopOrLaptop, isMobile]);

  return breakpoint;
};

export default useBreakpoints;
