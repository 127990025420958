import React from "react";

const LogoIcon = ({ fill }) => {
  return (
    <a href="/">
      <svg
        width="80"
        height="18"
        viewBox="0 0 80 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.4716 9.81483C15.3512 7.39173 15.2076 4.47674 15.2447 2.31204H15.166C14.5501 4.34527 13.8022 6.50771 12.8945 8.90135L9.71779 17.3153L7.97891 17.3063L5.14948 9.01695C4.31825 6.56437 3.61899 4.31807 3.13044 2.2531H3.07718C3.01467 4.41781 2.86648 7.33052 2.69746 9.9327L2.18575 17.3811L0 17.3698L1.32442 0L4.24647 0.0136066L7.23335 8.43214C7.96039 10.5742 8.55545 12.485 8.99538 14.2916H9.0741C9.53024 12.5417 10.1716 10.6354 10.9727 8.45027L14.175 0.0612054L17.097 0.0748051L18.1158 17.4559L15.8768 17.4445L15.467 9.81256L15.4716 9.81483Z"
          fill={fill}
        />
        <path
          d="M24.2841 12.0225L22.4132 17.4785L20.0422 17.4671L26.1596 0.122314L28.9242 0.135914L34.8933 17.5397L32.4436 17.5283L30.5751 12.0542L24.2818 12.0248L24.2841 12.0225ZM30.112 10.2975L28.3986 5.28813C28.0096 4.15251 27.7502 3.1189 27.4932 2.11248H27.44C27.1714 3.14156 26.8773 4.19785 26.5277 5.25186L24.7634 10.2703L30.1097 10.2953L30.112 10.2975Z"
          fill={fill}
        />
        <path
          d="M37.5908 0.407958C38.987 0.208488 40.6471 0.0611542 42.4647 0.070221C45.7573 0.0860879 48.0958 0.845432 49.6425 2.27119C51.2147 3.69694 52.127 5.71205 52.1131 8.52049C52.0992 11.3561 51.1915 13.6727 49.5244 15.2617C47.8573 16.8778 45.1136 17.7414 41.6636 17.7256C40.0312 17.7188 38.6628 17.6326 37.5051 17.4989L37.5908 0.407958ZM39.8043 15.783C40.3832 15.8895 41.226 15.919 42.1197 15.9235C47.0168 15.9462 49.6912 13.2783 49.7143 8.58622C49.7606 4.48802 47.4035 1.87224 42.5597 1.84958C41.3742 1.84278 40.4781 1.94251 39.8738 2.06945L39.8043 15.783Z"
          fill={fill}
        />
        <path
          d="M58.7651 0.278809L61.0551 0.290139L60.9972 11.9161C60.9741 16.5311 58.6494 17.9364 55.5953 17.9228C54.7525 17.9183 53.7268 17.7324 53.1479 17.499L53.4999 15.6697C53.9722 15.8533 54.6576 16.0097 55.4194 16.0143C57.4731 16.0233 58.6887 15.128 58.7073 11.7257L58.7651 0.281078V0.278809Z"
          fill={fill}
        />
        <path
          d="M79.9999 8.88778C79.9698 14.8696 76.242 18.0203 71.713 17.9999C67.0266 17.9773 63.7526 14.4049 63.7781 9.14392C63.8059 3.62676 67.3253 0.0340331 72.065 0.0589668C76.9111 0.0816339 80.0254 3.7333 79.9999 8.88778ZM66.2255 9.10538C66.207 12.8182 68.2445 16.1526 71.8519 16.1707C75.4594 16.1888 77.5571 12.9248 77.5757 8.98072C77.5919 5.52626 75.7673 1.9086 71.9492 1.89047C68.1566 1.87233 66.244 5.29279 66.2255 9.10765V9.10538Z"
          fill={fill}
        />
      </svg>
    </a>
  );
};
export default LogoIcon;
