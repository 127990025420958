import { Badge, Drawer, Popover } from "antd";
import * as React from "react";
import styledComponents from "styled-components";
import { Order, OrderItem, Product, shopService } from "../../../services/shopService.ts";
import CartPreview from "../../cart/preview/CartPreview.tsx";
import { OrderContext } from "../../../App.tsx";
import { useMediaQuery } from "react-responsive";
import BagIcon from "../../Icons/BagIcon.tsx";
import { Link } from "react-router-dom";
import Typography from "../../typography/index.tsx";
import { CloseOutlined } from "@ant-design/icons";

const BadgeStyled = styledComponents((props) => <Badge {...props} />)`
  cursor: pointer;  
  &:hover {
  svg{
    fill-opacity: 0;
    stroke-opacity: 0;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,   %3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.6667 4.16667H13.1944C13.1944 3.0616 12.7555 2.00179 11.9741 1.22039C11.1927 0.438987 10.1328 0 9.02778 0C7.92271 0 6.8629 0.438987 6.0815 1.22039C5.3001 2.00179 4.86111 3.0616 4.86111 4.16667H1.38889C1.02053 4.16667 0.667263 4.313 0.406796 4.57346C0.146329 4.83393 0 5.1872 0 5.55556V15.9722C0 16.3406 0.146329 16.6938 0.406796 16.9543C0.667263 17.2148 1.02053 17.3611 1.38889 17.3611H16.6667C17.035 17.3611 17.3883 17.2148 17.6488 16.9543C17.9092 16.6938 18.0556 16.3406 18.0556 15.9722V5.55556C18.0556 5.1872 17.9092 4.83393 17.6488 4.57346C17.3883 4.313 17.035 4.16667 16.6667 4.16667ZM9.02778 1.38889C9.76449 1.38889 10.471 1.68155 10.992 2.20248C11.5129 2.72342 11.8056 3.42995 11.8056 4.16667H6.25C6.25 3.42995 6.54266 2.72342 7.06359 2.20248C7.58453 1.68155 8.29107 1.38889 9.02778 1.38889Z' fill='%23767D93'/%3E%3C/svg%3E%0A ");
  }}
`;

const Bag = () => {
  const [order, setOrder] = React.useState<Order>();
  const [products, setProducts] = React.useState<Product[]>();
  const [open, setOpen] = React.useState(false);
  const { order: orderCount } = React.useContext(OrderContext);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const hide = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    (async () => {
      const orderId = localStorage.getItem("orderId");
      if (!orderId) return;
      const o = await shopService.getOrder(+orderId);
      if (!o) return;
      const promises = o.orderItems.map((item) => shopService.getProduct(item.productId));
      const products = await Promise.all(promises);
      setOrder(o);
      console.log(o);

      setProducts(products.filter((p) => !!p) as Product[]);
    })();
  }, [orderCount]);

  return (
    <>
      {isMobile ? (
        <>
          <BadgeStyled
            size="small"
            count={orderCount.length || 0}
            offset={[-1, 16]}
            color="black"
            onClick={() => setOpen(!open)}
          >
            <BagIcon />
          </BadgeStyled>
          <Drawer
            onClose={() => setOpen(false)}
            open={open}
            closable={false}
            placement="top"
            width={"auto"}
            height="410px"
            extra={<p>extra</p>}
            styles={{ body: { width: "100vw", height: "80vh" } }}
            style={{ height: 'auto', maxHeight: '80vh' }}
            bodyStyle={{ padding: "16px", alignSelf: "center" }}
            rootStyle={{ top: "55px" }}
          >
            <Typography variant="h3">{"Кошик".toUpperCase()}</Typography>
            <div style={{ float: "right", paddingBottom: "16px" }}>
              <CloseOutlined onClick={() => setOpen(false)} />
            </div>
            <CartPreview hide={hide} order={order!!} products={products!!} orderCount={orderCount} />
          </Drawer>
        </>
      ) : (
        <Popover
          content={<CartPreview hide={hide} order={order!!} products={products!!} orderCount={orderCount} />}
          title={<Typography variant="h3">{"Кошик".toUpperCase()}</Typography>}
          trigger="click"
          open={open}
          onOpenChange={(e) => setOpen(e)}
        >
          <BadgeStyled size="small" count={orderCount.length || 0} offset={[-1, 16]} color="black">
            <BagIcon />
          </BadgeStyled>
        </Popover>
      )}
    </>
  );
};

export default Bag;
