import React from "react";
const VisaIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2056_1998)">
        <path
          d="M22.222 15.7681L21.997 14.6431H19.483L19.083 15.7601L17.068 15.7641C18.363 12.6511 19.325 10.3461 19.952 8.84712C20.116 8.45512 20.407 8.25512 20.836 8.25812C21.164 8.26112 21.699 8.26112 22.442 8.25912L24 15.7651L22.222 15.7681ZM20.048 13.1021H21.669L21.064 10.2821L20.048 13.1021ZM7.062 8.25712L9.088 8.25912L5.956 15.7691L3.905 15.7671C3.38925 13.7834 2.87992 11.7981 2.377 9.81112C2.277 9.41512 2.079 9.13812 1.698 9.00712C1.13454 8.81891 0.568484 8.63856 0 8.46612L0 8.25912H3.237C3.797 8.25912 4.124 8.53012 4.229 9.08612C4.335 9.64312 4.601 11.0621 5.029 13.3401L7.062 8.25712ZM11.872 8.25912L10.271 15.7681L8.342 15.7651L9.94 8.25712L11.872 8.25912ZM15.782 8.12012C16.359 8.12012 17.086 8.30012 17.504 8.46612L17.166 10.0221C16.788 9.87012 16.166 9.66512 15.643 9.67212C14.883 9.68512 14.413 10.0041 14.413 10.3101C14.413 10.8081 15.229 11.0591 16.069 11.6031C17.027 12.2231 17.154 12.7801 17.142 13.3861C17.129 14.6411 16.069 15.8801 13.833 15.8801C12.813 15.8651 12.445 15.7801 11.613 15.4841L11.965 13.8601C12.812 14.2141 13.171 14.3271 13.895 14.3271C14.558 14.3271 15.127 14.0591 15.132 13.5921C15.136 13.2601 14.932 13.0951 14.188 12.6851C13.444 12.2741 12.4 11.7061 12.414 10.5631C12.431 9.10112 13.816 8.12012 15.782 8.12012Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2056_1998">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default VisaIcon;
