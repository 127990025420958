import { Carousel } from "antd";
import styled from "styled-components";

export const CarouselWrapper = styled(Carousel)`
  .slick-dots {
    margin-bottom: -16px;
  }
  > .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 0;
    border: 1px solid #767d93;
  }
  > .slick-dots li.slick-active button {
    width: 7px;
    height: 7px;
    background: #767d93;
  }
`;