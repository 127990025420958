import { Drawer, Flex, Input, message } from "antd";
import * as React from "react";

import useBreakpoints from "../../hooks/useBreakpoints.ts";
import Arrow from "../Icons/Arrow.tsx";
import Checkbox from "../Checkbox/index.tsx";
import Typography from "../typography/index.tsx";
import { styled as styledComponents } from "styled-components";
import { shopService } from "../../services/shopService.ts";
import { ProductSubscription } from "../../App.tsx";
import { CloseOutlined } from "@ant-design/icons";

const InputStyled = styledComponents((props) => <Input {...props} />)`
  color: white;
  border-bottom: 1px solid #767D93;
  border-radius: 0;
  padding: 16px 0; 
  justify-content: space-between;
  .ant-input:: placeholder { 
    color: white; 
    font-weight: 300;
    font-size: 12px;
    font-family: Namu;
  }
`;

const Footer = () => {
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);
  const [isActive, setActive] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const { productSubscription } = React.useContext(ProductSubscription);
  const [open, setOpen] = React.useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onClick = () => {
    console.log(email);

    if (productSubscription)
      shopService
        .subscribe({ email, productId: productSubscription })
        .then((status) => {
          console.log(status);

          showDrawer();
        });
  };

  return (
    <Flex
      gap="middle"
      vertical
      style={{
        textAlign: isMobile ? "center" : "left",
        display: !!productSubscription ? "flex" : "none",
      }}
    >
      <Typography> ПІДПИСАТИСЯ НА НАШУ РОЗСИЛКУ</Typography>
      {/* <Flex style={{ borderBottom: "1px solid #767D93", padding: "16px 0", justifyContent: "space-between" }}> */}
      <InputStyled
        placeholder="EMAIL"
        variant="borderless"
        value={email}
        onChange={(e) => {
          console.log(e.target.value);

          setEmail(e.target.value);
        }}
        suffix={
          <button
            type={"submit"}
            style={{
              margin: 0,
              padding: 0,
              borderWidth: 0,
              backgroundColor: "inherit",
            }}
            onClick={onClick}
            disabled={!isActive}
          >
            <Arrow />
          </button>
        }
        style={{ padding: 0 }}
        maxLength={255}
      />

      <Drawer
        onClose={onClose}
        open={open}
        height={"90px"}
        closable={false}
        placement="top"
        extra={<p>extra</p>}
      >
        <Typography variant="h1">
          Дякую, Ваш ємейл був успішно доданий!
        </Typography>
        <div style={{ float: "right" }}>
          <CloseOutlined onClick={onClose} />
        </div>
      </Drawer>

      {/* </Flex> */}

      <Flex
        style={{
          alignItems: "center",
          justifyContent: isMobile ? "center" : "left",
          gap: "12px",
        }}
        onClick={() => setActive(!isActive)}
      >
        <Checkbox isActive={isActive} />{" "}
        <Typography variant="uppercase">
          даю згоду на обробку моїх даних
        </Typography>
      </Flex>
    </Flex>
  );
};

export default Footer;
