import axios, { AxiosInstance } from "axios";

export interface Collection {
  collectionId: string;
  name: string;
  imageLink: string;
  createdDate: Date;
  products: Product[];
}

export interface Product {
  collectionId: string;
  createdDate: string;
  currencyId: number;
  description: string;
  materials: string;
  name: string;
  price: number;
  productId: string;
  productImages: ProductImage[];
  productSizes: ProductSize[];
  sex: number;
}

export interface ProductImage {
  is3dModel: boolean;
  isPrimary: boolean;
  link: string;
}

export interface ProductSize {
  sizeId: number;
  name: string;
  bodySize: string;
  total: number;
}

export interface AddOrderRequest {
  userSessionId: string;
  productId: string;
  sizeId: number;
}

export interface Order {
  orderId: number;
  orderStatusId: number;
  userSessionId: string;
  orderItems: OrderItem[];
  createdDate: Date;
}

export interface OrderItem {
  orderItemId: number;
  productId: string;
  sizeId: number;
  quantity: number;
  createdDate: Date;
}

export enum Currency {
  "₴" = 1,
  "$" = 2,
  "€" = 3,
}

export enum Sex {
  Male = 0,
  Female = 1,
  Unisex = 2,
}

export enum Size {
  XXS = 1,
  XS = 2,
  S = 3,
  M = 4,
  L = 5,
  XL = 6,
  XXL = 7,
  M_L = 8,
  L_XL = 9,
  XS_S = 10,
  S_M = 11,
}

export enum OrderStatus {
  Created = 1,
  Paid = 2,
  Deleted = 254,
  AutoDeleted = 255,
}

export interface City {
  description: string;
  cityID: string;
}

export interface GetCityRequest {
  findByString: string;
}

export interface Department {
  siteKey: string;
  description: string;
}

export interface GetDepartmentRequest {
  findByString: string;
  cityName: string;
  page: number;
  limit: number;
}
export interface Research {
  researchId: string;
  name: string;
  description: string;
  paperLink: string;
  imageLink: string;
  createdDate: Date;
}

export interface CheckoutRequest {
  name: string;
  surname: string;
  parentalName: string;
  phoneNumber: string;
  email: string;
  city: string;
  deliveryAddress: string;
  isCallMeBack: true;
  comment: string;
}

export interface CheckoutResponse {
  canProcess: true;
  message: string;
}

export interface SubscribeRequest {
  email: string;
  productId: string;
}

class ShopService {
  private connection;

  private getConnection(): AxiosInstance {
    if (!this.connection)
      this.connection = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL!,
        headers: { accept: "application/json" },
        withCredentials: false,
      });
    return this.connection;
  }

  public async getCollection(collectionId: any): Promise<Product[] | undefined> {
    try {
      const res = await this.getConnection().get(`products/collection/${collectionId}`);
      return res.data as Product[];
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async getCollections(): Promise<Collection[] | undefined> {
    try {
      const res = await this.getConnection().get("collections");
      return res.data as Collection[];
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async getProduct(productId: string): Promise<Product | undefined> {
    try {
      const res = await this.getConnection().get(`products/${productId}`);
      return res.data as Product;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async getTopProducts(sessionId: string, collectionId: string): Promise<Product[] | undefined> {
    try {
      const res = await this.getConnection().get(`products/top/${sessionId}?collectionId=${collectionId}`);

      return res.data as Product[];
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async addOrder(body: AddOrderRequest) {
    try {
      const res = await this.getConnection().post("orders", body);
      console.log(res);
      return res.data as Order;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async getOrder(orderId: number): Promise<Order | undefined> {
    try {
      const res = await this.getConnection().get(`orders/${orderId}`);
      console.log(res);
      
      return res.data as Order;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async deleteOrderItem(orderId: number, orderItemId: number, sessionId: string): Promise<boolean | undefined> {
    try {
      const res = await this.getConnection().delete(
        `orders/${orderId}/items/${orderItemId}?userSessionId=${sessionId}`
      );
      console.log(res.status);
      return res.status === 200;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async getCities(body: GetCityRequest) {
    try {
      const res = await this.getConnection().post("novaposhta/get-cities", body);
      console.log(res);
      return res.data.data as City[];
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async getDepartments(body: GetDepartmentRequest) {
    try {
      const res = await this.getConnection().post("novaposhta/get-warehouses", body);
      console.log(res);
      return res.data.data as Department[];
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async getResearches(): Promise<Research[] | undefined> {
    try {
      const res = await this.getConnection().get(`research`);
      return res.data as Research[];
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async getResearch(researchId: string): Promise<Research | undefined> {
    try {
      const res = await this.getConnection().get(`research/${researchId}`);
      return res.data as Research;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async getSexBasedProducts(sex?: number, collectionId?: string, search: string = ''): Promise<Product[] | undefined> {
    try {
      const res = await this.getConnection().get(`products/filtered?&pageNumber=1&pageSize=16`, { params: { sex, collectionId, search } });
      return res.data as Product[];
    } catch (e) {
      console.log(e);
    }
  }

  public async getAllSexBasedProducts(collectionId: string, search: string = ''): Promise<Product[] | undefined> {
    try {
      const res = await this.getConnection().get(`products/filtered?&pageNumber=1&pageSize=16`, { params: { collectionId, search } });
      return res.data as Product[];
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async postCheckout(body: CheckoutRequest): Promise<CheckoutResponse | undefined> {
    try {
      const res = await this.getConnection()
        .post(`orders/checkout?orderId=${localStorage.getItem("orderId")}&userSessionId=${localStorage.getItem("sessionId")}`, body);
      return res.data as CheckoutResponse;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async subscribe(body: SubscribeRequest): Promise<number | undefined> {
    try {
      const res = await this.getConnection().post(`subscriberemail`, body);
      console.log(res);

      return res.status;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }
}

export const shopService = new ShopService();
