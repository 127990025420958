import { Col, Divider, Flex, Row } from "antd";
import * as React from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import CartProductView from "../../components/cart/CartProductView.tsx";
import { Currency, Order, OrderItem, Product, shopService } from "../../services/shopService.ts";
import CheckoutList from "../../components/cart/CheckoutList.tsx";
import MainButton from "../../components/main/MainButton.tsx";
import Typography from "../../components/typography/index.tsx";
import Payment from "../../components/Icons/Payment.tsx";
import Material from "../../components/Icons/Material.tsx";
import Delivery from "../../components/Icons/Delivery.tsx";
import { OrderContext } from "../../App.tsx";
import useBreakpoints from "../../hooks/useBreakpoints.ts";
import MobileOrderView from "../mobile/MobileOrderView.tsx";

interface OrderViewInterface {
  order: Order;
  products: Product[];
}

export const orderLoader = async (): Promise<OrderViewInterface | undefined> => {
  const orderId = localStorage.getItem("orderId");
  if (!orderId) return;
  const order = await shopService.getOrder(+orderId);
  if (!order) return;
  const promises = order.orderItems.map((item) => shopService.getProduct(item.productId));
  const products = await Promise.all(promises);
  return { order, products: products.filter((p) => !!p) as Product[] };
};

const OrderView = () => {
  const { order, products } = useLoaderData() as OrderViewInterface;
  const { order: orderCount } = React.useContext(OrderContext);
  const navigate = useNavigate();
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);
  
  const findProduct = (orderItem: OrderItem) : Product => {
    return products.find(p => p.productId === orderItem.productId)!!;
  }

  if (isMobile) return <MobileOrderView products={products} orderCount={orderCount} order={order} />
  return (
    <>
      <Row>
        <Col span={isMobile ? 12 : 16} style={{ background: "#FCFCFC" }}>
          <Row justify={"space-between"} align={"middle"} style={{ padding: "16px" }}>
            <p style={{ fontSize: "18px", margin: 0 }}>
              {`Мій кошик (${orderCount.length})`.toUpperCase()}
            </p>
            <MainButton text={"Продовжити покупку".toUpperCase()} onClick={() => navigate(-1)} />
          </Row>
          {orderCount.map((item) => (
            <CartProductView product={products.find(p => p.productId === item.productId)!!} order={order} orderItem={item} />
          ))}
        </Col>
        <Col span={isMobile ? 12 : 8} style={{ padding: "16px", background: "#FFFFFF" }}>
          <Row justify={"space-between"}>
            <p style={{ fontSize: "14px", margin: 0 }}>{"TOTAL:"}</p>
            <p style={{ fontSize: "14px", margin: 0, float: "right" }}>
              {orderCount.length > 0 ? Currency[products[0].currencyId] : ''}
              {orderCount.reduce((sum, item) => sum + findProduct(item).price, 0)}
            </p>
          </Row>
          <Row>
            <Divider style={{ background: "#D0D4DD" }} />
          </Row>
          <Row style={{ paddingBottom: "16px" }}>
            <Link to={"/checkout"}>
              <MainButton text={"Перейти до оформлення".toUpperCase()} />
            </Link>
          </Row>
          <Row>
            <Flex vertical gap="16px">
              <Flex gap="12px">
                <div style={{ width: "33px", height: "33px" }}>
                  <Payment />
                </div>

                <Flex vertical gap="4px">
                  <Typography variant="h5">Оплата</Typography>
                  <Typography variant="contentSmall">Кредитна картка, дебетова картка, PayPal або Apple Pay</Typography>
                </Flex>
              </Flex>
              <Flex gap="12px">
                <div style={{ width: "33px", height: "33px" }}>
                  <Material />
                </div>
                <Flex vertical gap="4px">
                  <Typography variant="h5">Відправлення та доставка</Typography>
                  <Typography variant="contentSmall">Вартість доставки залежить від компанії-перевізника</Typography>
                </Flex>
              </Flex>
              <Flex gap="12px">
                <div style={{ width: "33px", height: "33px" }}>
                  <Delivery />
                </div>
                <Flex vertical gap="4px">
                  <Typography variant="h5">Повернення та обмін</Typography>
                  <Typography variant="contentSmall">Безкоштовно</Typography>
                </Flex>
              </Flex>
            </Flex>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default OrderView;
