import * as React from "react";

const Checkbox = ({ isActive }) => {
  return isActive ? (
    <div style={{ cursor: "pointer" }}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.5" y="1.5" width="13" height="13" stroke="#9FB3D6" />
        <path d="M5 8L7 10L11 6" stroke="#9FB3D6" />
      </svg>
    </div>
  ) : (
    <div style={{ cursor: "pointer" }}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="15" height="15" stroke="#767D93" />
      </svg>{" "}
    </div>
  );
};

export default Checkbox;
