import { Col, Divider, Drawer, Flex, Image, Row, Segmented, Space } from "antd";
import * as React from "react";
import { Link, useLoaderData } from "react-router-dom";
import ButtonWithArrow from "../../components/buttons/ButtonWithArrow.tsx";
import Loading from "../../components/loader/Loading.tsx";
import MainButton from "../../components/main/MainButton.tsx";
import Typography from "../../components/typography/index.tsx";
import { Currency, Product, shopService } from "../../services/shopService.ts";
import ProductPreview from "./ProductPreview.tsx";
import { OrderContext, ProductSubscription } from "../../App.tsx";
import { CloseOutlined } from "@ant-design/icons";
import useBreakpoints from "../../hooks/useBreakpoints.ts";
import ProductViewMobile from "../mobile/ProductViewMobile.tsx";
import MadjoIcon from "../../components/Icons/MadjoIcon.tsx";

export const productLoader = async ({
  params,
}): Promise<Product | undefined> => {
  const product = await shopService.getProduct(params.productId);
  return product;
};

const ProductView = () => {
  const product = useLoaderData() as Product;
  const [topProducts, setTopProducts] = React.useState<Product[]>();
  const [open, setOpen] = React.useState(false);
  const [bodySize, setBodySize] = React.useState<string>(product.productSizes.filter((s) => s.total !== 0)[0]?.bodySize);
  const [sizeId, setSizeId] = React.useState<number | undefined>(
    product.productSizes.filter((s) => s.total !== 0)[0]?.sizeId
  );
  const [order, setOrder] = React.useState<boolean>(true);
  const [isSelected, setSelected] = React.useState(!!sizeId || false);
  const { order: order2, addToCart } = React.useContext(OrderContext);
  const { setProductSubscription } = React.useContext(ProductSubscription);
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setProductSubscription(product.productId);
    const sessionId = localStorage.getItem("sessionId");

    if (sessionId)
      shopService
        .getTopProducts(sessionId.toString(), product.collectionId)
        .then(setTopProducts);
    return () => {
      setProductSubscription(null);
    };
  }, [product]);

  const addToOrder = React.useCallback(() => {
    setOrder(false);
    const sessionId = localStorage.getItem("sessionId");
    product.productSizes.find((size) => size.sizeId === sizeId)!!.total--;
    setSelected(
      product.productSizes.find((size) => size.sizeId === sizeId)!!.total !== 0
    );
    if (sessionId && sizeId)
      shopService
        .addOrder({
          userSessionId: sessionId,
          productId: product.productId,
          sizeId,
        })
        .then((order) => {
          if (order) {
            addToCart(order);
            setOrder(true);
            localStorage.setItem("orderId", order.orderId.toString());
          }
        });
  }, [product, sizeId]);

  if (product === undefined) return <Loading size={"56px"} />;
  else if (isMobile)
    return (
      <ProductViewMobile
        product={product}
        isSelected={isSelected}
        order={order}
        addToOrder={addToOrder}
        setSizeId={setSizeId}
        bodySize={bodySize}
        setBodySize={setBodySize}
        showDrawer={showDrawer}
        onClose={onClose}
        topProducts={topProducts}
        open={open}
      />
    );
  else
    return (
      <div style={{ padding: "8px", paddingBottom: "2.5rem" }}>
        <Flex>
          <Flex style={{ width: "50%", padding: "8px" }} vertical={true}>
            {/* <Carousel dots dotPosition="left" infinite={false}> */}
            {product.productImages.map((img) => (
              <Image
                width={"auto"}
                src={img.link}
                alt={product.description}
                preview={false}
              />
            ))}
            {/* </Carousel> */}
          </Flex>
          <Space />
          <Flex style={{ width: "50%" }} vertical>
            <div style={{ position: "sticky", top: "25%", paddingLeft: "20%" }}>
              <p style={{ fontSize: "18px" }}>{product.name.toUpperCase()}</p>
              <Flex align="center" gap="middle">
                <Typography variant="uppercase">Розмір:</Typography>
                <Segmented
                  selected={isSelected}
                  disabled={!isSelected}
                  size="small"
                  options={product.productSizes.map((s) => ({
                    label: s.name,
                    value: s,
                    disabled: s.total === 0,
                  }))}
                  defaultValue={
                    product.productSizes.filter((s) => s.total !== 0)[0]
                  }
                  onChange={function (newSize) {
                    setBodySize(newSize.bodySize);
                    return setSizeId(newSize.sizeId);
                  }}
                />
                {bodySize}
              </Flex>
              <p style={{ fontSize: "32px", margin: "16px 0" }}>
                {Currency[product.currencyId]}
                {product.price}
              </p>
              <Flex vertical gap="40px">
                <MainButton
                  text={"Купити".toUpperCase()}
                  disabled={!order || !isSelected}
                  onClick={addToOrder}
                />
                <ButtonWithArrow
                  text={"Деталі про товар".toUpperCase()}
                  onClick={showDrawer}
                />
              </Flex>
              <Drawer
                onClose={onClose}
                open={open}
                width={"48%"}
                style={{ position: "relative" }}
                closable={false}
              >
                <div style={{ float: "right" }}>
                  <CloseOutlined onClick={onClose} />
                </div>
                <div style={{ padding: "20px 56px" }}>
                  <Typography variant="h2" style={{ cursor: "auto" }}>
                    Детальна інформація про продукт
                  </Typography>
                  <div style={{ padding: "8px 0" }} />
                  <Typography variant="h3" style={{ cursor: "auto" }}>
                    {product.description}
                  </Typography>
                  <Divider />
                  <Typography variant="h3" style={{ cursor: "auto" }}>
                    Матеріали
                  </Typography>
                  <ul>
                    {product.materials.split(", ").map((material) => (
                      <li>
                        <Typography variant="h4">{material}</Typography>
                      </li>
                    ))}
                  </ul>
                </div>
                <div style={{ position: "fixed", bottom: "16px" }}>
                  {Array.from({ length: 3 }, (_, index) => (
                    <MadjoIcon key={index} />
                  ))}
                </div>
              </Drawer>
            </div>
          </Flex>
        </Flex>
        <Typography variant="h3" style={{ padding: "8px" }}>
          {"Популярні товари"}
        </Typography>
        <Row>
          {topProducts &&
            topProducts.map(
              (p) =>
                p.productImages &&
                p.productImages.length > 0 && (
                  <Col span={6}>
                    <Link to={`/products/${p.productId}`}>
                      <ProductPreview key={p.productId} product={p} />
                    </Link>
                  </Col>
                )
            )}
        </Row>
      </div>
    );
};

export default ProductView;
