import React from "react";
import { v4 } from 'uuid';

const CookieProvider = ({ children }) => {
    React.useEffect(() => {
        if (!localStorage.getItem("sessionId")) {
            localStorage.setItem("sessionId", v4())
        }
    }, [])
    return (<>{children}</>)
}

export default CookieProvider;