import * as React from "react";

const HeaderMenu = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="4" width="4" height="4" fill="#767D93" />
      <rect x="1" y="12" width="4" height="4" fill="#767D93" />
      <rect x="8" y="4" width="4" height="4" fill="#767D93" />
      <rect x="8" y="12" width="4" height="4" fill="#767D93" />
      <rect x="15" y="4" width="4" height="4" fill="#767D93" />
      <rect x="15" y="12" width="4" height="4" fill="#767D93" />
    </svg>
  );
};

export default HeaderMenu;
