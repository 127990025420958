import * as React from "react";
import styled from "styled-components";

const MainButtonText = styled.p`
  width: fit-content;
  padding-bottom: 8px;
  margin: 0;
  border-bottom: 1px solid #9fb3d6;
  cursor: pointer;
  color: #000; /* Set the text color explicitly. Iphone shit bug is making it invisible!! */
  &:hover {
    background-color: #9fb3d6;
  }
`;

interface MainButtonInterface {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties | undefined;
  disabled?: boolean;
  alignButton?: string;
  type?: "button" | "submit" | "reset" | undefined;
  margin?: string;
}

const MainButton = ({ text, onClick, disabled = false, alignButton, type = "button", margin, ...style }: MainButtonInterface) => {
  return (
    <div style={{ alignSelf: alignButton, margin }}>
      <button
        type={type}
        style={{ margin: 0, padding: 0, borderWidth: 0, backgroundColor: "inherit", ...style }}
        onClick={onClick}
        disabled={disabled}
      >
        <MainButtonText>{text}</MainButtonText>
      </button>
    </div>
  );
};

export default MainButton;
