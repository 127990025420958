import * as React from "react";
import MainButton from "../main/MainButton.tsx";
import { remove } from "lodash";
import { OrderItem, Product, shopService } from "../../services/shopService.ts";
import { OrderContext } from "../../App.tsx";

interface DeleteItemInterface {
  product: Product;
  orderItems: OrderItem[];
}

const DeleteItem = ({ product, orderItems }: DeleteItemInterface) => {
  const [orderId, setOrderId] = React.useState<number | null>();
  const { order: order2, removeFromCart } = React.useContext(OrderContext);

  React.useEffect(() => {
    const id = localStorage.getItem("orderId");
    if (id) setOrderId(+id);
  }, []);

  const deleteFromOrder = () => {
    const orderItem = remove(
      orderItems!!,
      (item) => item.productId === product.productId
    ).pop() as OrderItem;
    const sessionId = localStorage.getItem("sessionId");
    if (orderItem && sessionId && orderId)
      shopService
        .deleteOrderItem(orderId, orderItem.orderItemId, sessionId)
        .then(() => removeFromCart(orderItem));
  };
  return (
    <MainButton text={"Видалити".toUpperCase()} onClick={deleteFromOrder} />
  );
};

export default DeleteItem;
