import { Divider, Flex, Form, Input } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";
import { shopService } from "../../services/shopService.ts";
import CheckboxGrey from "../Checkbox/CheckboxGrey.tsx";
import MainButton from "../main/MainButton.tsx";
import { generateLiqPaySignature } from "../payment/utils.ts";
import Typography from "../typography/index.tsx";
import CityDeliveryForm from "./CityDeliveryForm.tsx";
import DepartmentDeliveryForm from "./DepartmentDeliveryForm.tsx";
import MobileContactForm from "../../views/mobile/MobileContactForm.tsx";
import useBreakpoints from "../../hooks/useBreakpoints.ts";

interface ContactFormInterface {
  price: number;
}

const ContactForm = ({ price }: ContactFormInterface) => {
  const [city, setCity] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [isDeliveryActive, setDeliveryActive] = React.useState(true);
  const [isCallMeBack, setIsCallMeBack] = React.useState(false);
  const [isReadActive, setIsReadActive] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);

  const orderData = React.useMemo(() => {
    return {
      amount: price.toString(),
      currency: process.env.REACT_APP_CURRENCY!,
      customer_user_id: localStorage.getItem("sessionId")!,
      description: "",
      order_id: localStorage.getItem("orderId")!,
      action: "pay",
      server_url: process.env.REACT_APP_SERVER_CALLBACK_URL!,
      result_url: process.env.REACT_APP_RESPONSE_URL!,
    };
  }, [price]);

  const { data, signature } = React.useMemo(() => {
    return generateLiqPaySignature(
      process.env.REACT_APP_LIQPAY_PUBLIC_KEY!,
      process.env.REACT_APP_LIQPAY_PRIVATE_KEY!,
      orderData
    );
  }, [orderData]);

  const onFinish = async (values) => {
    setLoading(true);
    const res = await shopService.postCheckout({
      ...values,
      isCallBackActive: isCallMeBack,
    })!!;
    setLoading(false);
    console.log("Success:", values);
    if (!res?.canProcess) return;

    postData("https://www.liqpay.ua/api/3/checkout", {
      data: data,
      signature: signature,
    });
  };

  function postData(path, params) {
    // Create form
    const hidden_form = document.createElement("form");

    // Set method to post by default
    hidden_form.method = "post";

    // Set path
    hidden_form.action = path;

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hidden_input = document.createElement("input");
        hidden_input.type = "hidden";
        hidden_input.name = key;
        hidden_input.value = params[key];

        hidden_form.appendChild(hidden_input);
      }
    }

    document.body.appendChild(hidden_form);
    hidden_form.submit();
  }

  if (isMobile)
    return (
      <MobileContactForm
        price={price}
        onFinish={onFinish}
        data={data}
        signature={signature}
        loading={loading}
        city={city}
        setCity={setCity}
        department={department}
        setDepartment={setDepartment}
        isDeliveryActive={isDeliveryActive}
        setDeliveryActive={setDeliveryActive}
        isCallMeBack={isCallMeBack}
        setIsCallMeBack={setIsCallMeBack}
        isReadActive={isReadActive}
        setIsReadActive={setIsReadActive}
      />
    );
  return (
    <Form onFinish={onFinish}>
      <input type="hidden" name="data" value={data} />
      <input type="hidden" name="signature" value={signature} />
      <Flex vertical gap={"16px"} style={{ padding: "0 16px" }}>
        <Typography style={{ textTransform: "uppercase" }} variant="h4">
          Контактні Дані
        </Typography>
        <Flex vertical gap={"16px"}>
          <Flex gap={"16px"}>
            <Form.Item
              name={"surname"}
              style={{ width: "-webkit-fill-available" }}
              rules={[{ required: true, message: "Відсутнє прізвище" }]}
            >
              <Input placeholder="Прізвище*" style={{ borderRadius: 0 }} />
            </Form.Item>
            <Form.Item
              name={"name"}
              style={{ width: "-webkit-fill-available" }}
              rules={[{ required: true, message: "Відсутнє ім'я" }]}
            >
              <Input placeholder="Ім’я*" style={{ borderRadius: 0 }} />
            </Form.Item>
          </Flex>
          <Flex gap={"16px"}>
            <Form.Item
              name={"parentalName"}
              style={{ width: "-webkit-fill-available" }}
              rules={[{ required: false }]}
            >
              <Input placeholder="По батькові" style={{ borderRadius: 0 }} />
            </Form.Item>
            <Form.Item
              name={"phoneNumber"}
              style={{ width: "-webkit-fill-available" }}
              rules={[{ required: true, message: "Відсутній номер телефону" }]}
            >
              <Input
                placeholder="Номер телефону*"
                style={{ borderRadius: 0 }}
              />
            </Form.Item>
          </Flex>
          <Flex vertical>
            <Form.Item
              name={"email"}
              rules={[{ required: true, message: "Відсутня електронна пошта" }]}
            >
              <Input placeholder="E-mail" style={{ borderRadius: 0 }} />
            </Form.Item>
          </Flex>
        </Flex>
        <CityDeliveryForm
          city={city}
          setCity={setCity}
          setActive={setDeliveryActive}
          isActive={isDeliveryActive}
        />
        <DepartmentDeliveryForm
          city={city}
          department={department}
          setDepartment={setDepartment}
          deliveryEnabled={isDeliveryActive}
        />
        <Divider style={{ background: "#D0D4DD" }} />
        <Form.Item name={"comment"} rules={[{ required: false }]}>
          <Input
            placeholder="Додати коментар до замовлення"
            style={{ borderRadius: 0 }}
          />
        </Form.Item>

        <Flex gap={"small"} onClick={() => setIsCallMeBack(!isCallMeBack)}>
          <CheckboxGrey isActive={isCallMeBack} />{" "}
          <Typography variant="uppercase" style={{ color: "#767D93" }}>
            Передзвоніть мені
          </Typography>
        </Flex>
        <Form.Item
          name={"checkbox"}
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                isReadActive
                  ? Promise.resolve()
                  : Promise.reject(new Error("Необхідно погодитися")),
            },
          ]}
        >
          <Flex gap={"small"} onClick={() => setIsReadActive(!isReadActive)}>
            <CheckboxGrey isActive={isReadActive} />{" "}
            <Typography variant="uppercase" style={{ color: "#767D93" }}>
              Я прочитав(ла){" "}
              <Link
                to={"/privacy-policy"}
                style={{ textDecorationLine: "underline", color: "#9FB3D6" }}
              >
                умови та правила
              </Link>
              , та погоджуюсь з ними
            </Typography>
          </Flex>
        </Form.Item>

        <MainButton
          text={"Оформити замовлення".toUpperCase()}
          margin={"0 0 16px 0"}
          type={"submit"}
          alignButton="center"
          disabled={loading || price === 0}
        />
      </Flex>
    </Form>
  );
};

export default ContactForm;
