import React from "react";
const BagIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.6667 5.16667H14.1944C14.1944 4.0616 13.7555 3.00179 12.9741 2.22039C12.1927 1.43899 11.1328 1 10.0278 1C8.92271 1 7.8629 1.43899 7.0815 2.22039C6.3001 3.00179 5.86111 4.0616 5.86111 5.16667H2.38889C2.02053 5.16667 1.66726 5.313 1.4068 5.57346C1.14633 5.83393 1 6.1872 1 6.55556V16.9722C1 17.3406 1.14633 17.6938 1.4068 17.9543C1.66726 18.2148 2.02053 18.3611 2.38889 18.3611H17.6667C18.035 18.3611 18.3883 18.2148 18.6488 17.9543C18.9092 17.6938 19.0556 17.3406 19.0556 16.9722V6.55556C19.0556 6.1872 18.9092 5.83393 18.6488 5.57346C18.3883 5.313 18.035 5.16667 17.6667 5.16667ZM10.0278 2.38889C10.7645 2.38889 11.471 2.68155 11.992 3.20248C12.5129 3.72342 12.8056 4.42995 12.8056 5.16667H7.25C7.25 4.42995 7.54266 3.72342 8.06359 3.20248C8.58453 2.68155 9.29107 2.38889 10.0278 2.38889ZM18 17.5H2V6H5.86111V7.94444C5.86111 8.12862 5.93428 8.30526 6.06451 8.43549C6.19474 8.56572 6.37138 8.63889 6.55556 8.63889C6.73973 8.63889 6.91637 8.56572 7.0466 8.43549C7.17684 8.30526 7.25 8.12862 7.25 7.94444V6H12.8056V7.94444C12.8056 8.12862 12.8787 8.30526 13.009 8.43549C13.1392 8.56572 13.3158 8.63889 13.5 8.63889C13.6842 8.63889 13.8608 8.56572 13.991 8.43549C14.1213 8.30526 14.1944 8.12862 14.1944 7.94444V6H18V17.5Z"
        fill="#767D93"
      />
    </svg>
  );
};
export default BagIcon;
