import { Flex, Image } from "antd";
import React from "react";
import Typography from "../../components/typography/index.tsx";
import { Research, shopService } from "../../services/shopService.ts";
import MadjoIcon from "../../components/Icons/MadjoIcon.tsx";
import { Link, useLoaderData } from "react-router-dom";
import useBreakpoints from "../../hooks/useBreakpoints.ts";
import parse from "html-react-parser";

export const researchesLoader = async (): Promise<Research[] | undefined> => {
  const research = (await shopService.getResearches()) as Research[];
  return research;
};

const ResearchView = () => {
  const researches = useLoaderData() as Research[];
  const breakpoint = useBreakpoints();
  const isDesktop = ["xl"].includes(breakpoint);
  const isMobile = ["xs"].includes(breakpoint);
  return (
    <Flex
      vertical
      style={{ justifySelf: "center", marginBottom: "80px" }}
      justify="center"
    >
      <Flex vertical style={{ margin: "80px 0 40px" }}>
        <Typography
          variant="h2"
          style={{
            textTransform: "uppercase",
            marginBottom: "40px",
            alignSelf: "center",
          }}
        >
          НАШІ ДОСЛІДЖЕННЯ
        </Typography>
        <Flex justify="center" gap="12px" style={{ marginBottom: "40px" }}>
          {Array.from({ length: 3 }, (_, index) => (
            <MadjoIcon key={index} />
          ))}
        </Flex>
        <Flex
          style={
            !isMobile
              ? {
                  gridTemplateColumns: isDesktop
                    ? "repeat(3, 340px)"
                    : " repeat(2, 340px)",
                  gridGap: "40px",
                  display: "grid",
                }
              : { justifyContent: "center" }
          }
        >
          {researches &&
            researches.map((item) => (
              <Link to={`/research/${item.researchId}`}>
                <Flex vertical style={{ maxWidth: "340px" }}>
                  <Image src={item.imageLink} preview={false} />
                  <Flex
                    vertical
                    gap="6px"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography>{item.name}</Typography>
                    <Typography
                      variant="h5"
                      style={{
                        color: "#767D93",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.description.replace(/(<([^>]+)>)/ig,'')}
                    </Typography>
                  </Flex>
                </Flex>
              </Link>
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ResearchView;
