import * as React from "react";

const RectangleDivider = () => {
  return (
    <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="4" height="4" fill="#9FB3D6" />
    </svg>
  );
};

export default RectangleDivider;
