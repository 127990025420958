import { Input, Select } from "antd";
import React from "react";
import { styled as styledComponents } from "styled-components";

export const InputStyled = styledComponents((props) => <Input {...props} />)<{
  color?: string;
  fontSize?: string;
}>`
  .ant-input:: placeholder { 
    color: ${({ color }) => color || "black"}; 
    font-weight: 300;
    font-size: ${({ fontSize }) => fontSize || "12px"};
    font-family: Namu;
  }
`;

export const SelectStyled = styledComponents((props) => <Select {...props} />)<{}>`
  .ant-select-selection-item {
    color: #767D93;
  }
`