import React from "react";
const Delivery = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.703 21.8027H25.023C26.727 21.7747 29.3337 22.672 29.3337 25.9093C29.3337 29.2693 26.1177 29.8333 25.0217 29.8333H10.595C7.25099 29.8333 2.66699 29.1547 2.66699 23.3933V11.1693H29.3337V17.192M23.3363 19.1347L21.067 21.028C20.9563 21.1248 20.8668 21.2435 20.8041 21.3766C20.7415 21.5097 20.7071 21.6543 20.703 21.8013C20.6992 21.9582 20.7301 22.114 20.7935 22.2576C20.8569 22.4012 20.9512 22.529 21.0697 22.632L23.3363 24.476M2.67499 11.1547L3.90299 8.088C4.90033 5.70267 5.39899 4.51067 6.40966 3.84C7.42033 3.16933 8.71632 3.16667 11.307 3.16667H20.667C23.259 3.16667 24.555 3.16667 25.5657 3.83867C26.5763 4.50933 27.0737 5.70133 28.0723 8.08533L29.3337 11.16M15.951 11.168V3.168M13.2843 16.5013H18.6177"
        stroke="#9FB3D6"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default Delivery;
