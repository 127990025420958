import React from "react";
import useBreakpoints from "../../hooks/useBreakpoints.ts";

const TikTok = () => {
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);

  return isMobile ? (
    <svg width="28px" height="28px" viewBox="0 0 448 512" style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"
        fill="white"
      />
    </svg>
  ) : (
    <svg width="16px" height="16px" viewBox="0 0 448 512" style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"
        fill="white"
      />
    </svg>
  );
};

export default TikTok;
