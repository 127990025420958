import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Layout from "../components/layout/index.tsx";
import Main, { mainLoader } from "../views/Main.tsx";
import CollectionView, { collectionLoader } from "../views/collections/CollectionView.tsx";
import CheckoutView, { checkoutLoader } from "../views/order/CheckoutView.tsx";
import OrderView, { orderLoader } from "../views/order/OrderView.tsx";
import PrivacyPolicyView from "../views/privacyPolicy/PrivacyPolicyView.tsx";
import ProductView, { productLoader } from "../views/products/ProductView.tsx";
import ResearchView, { researchesLoader } from "../views/research/ResearchView.tsx";
import PublicOfferView from "../views/privacyPolicy/PublicOfferView.tsx";
import SexView from "../views/collections/SexView.tsx";
import ResearchDescription, { researchLoader } from "../views/research/ResearchDescription.tsx";
import PaymentSuccess from "../views/payment/PaymentSuccess.tsx";
import NotFound from "../components/errors/NotFound.tsx";
import AllSexView from "../views/collections/AllSexView.tsx";

export const customRouter = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      { path: "/", element: <Main />, children: [
        
      ] },
      {
        path: "/products/:productId",
        element: <ProductView />,
        loader: productLoader,
      },
      {
        path: "/men",
        element: <SexView sex={0} collectionId={'f7744a9d-f8a5-4b82-b00f-274139cd737b'} />,
      },
      {
        path: "/women",
        element: <SexView sex={1} collectionId={'f7744a9d-f8a5-4b82-b00f-274139cd737b'} />,
      },
      {
        path: "/spring-2025",
        element: <AllSexView collectionId={'5c4d326e-3586-4dfb-9f1b-cbc5ba772e45'} />,
      },
      {
        path: "/autumn-2024",
        element: <AllSexView collectionId={'f7744a9d-f8a5-4b82-b00f-274139cd737b'} />,
      },
      {
        path: "/collections/:collectionId",
        element: <CollectionView collectionId={'f7744a9d-f8a5-4b82-b00f-274139cd737b'}/>,
        loader: collectionLoader,
      },
      {
        path: "/order",
        element: <OrderView />,
        loader: orderLoader,
      },
      {
        path: "/checkout",
        element: <CheckoutView />,
        loader: checkoutLoader,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicyView />,
      },
      {
        path: "/public-offer",
        element: <PublicOfferView />,
      },
      {
        path: "/research",
        element: <ResearchView />,
        loader: researchesLoader,
      },
      {
        path: "/research/:researchId",
        element: <ResearchDescription />,
        loader: researchLoader,
      },
      {
        path: "/page/success",
        element: <PaymentSuccess />
      }
    ],
  },
]);
