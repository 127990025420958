import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Text = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
`;

interface ITypography {
  variant?: string;
  style?: React.CSSProperties | undefined;
  onClick?: React.MouseEventHandler<HTMLSpanElement> | undefined;
}

const Typography = ({ variant, children, style, onClick }: PropsWithChildren<ITypography>) => {
  if (variant === "bold") {
    return (
      <Text style={{ textShadow: '1px 0px 0px #000', paddingRight: '1px', letterSpacing: '1px', ...style }} onClick={onClick}>
        {children}
      </Text>
    );
  }
  if (variant === "uppercase")
    return (
      <Text style={{ textTransform: "uppercase", cursor: "pointer", ...style }} onClick={onClick}>
        {children}
      </Text>
    );
  if (variant === "underline")
    return (
      <Text
        style={{
          textTransform: "uppercase",
          textDecoration: "underline",
          cursor: "pointer",
          ...style,
        }}
        onClick={onClick}
      >
        {children}
      </Text>
    );
  if (variant === "h5")
    return (
      <Text style={{ fontSize: "14px", lineHeight: "16.8px", ...style }} onClick={onClick}>
        {children}
      </Text>
    );

  if (variant === "h4")
    return (
      <Text style={{ fontSize: "16px", lineHeight: "19.2px", ...style }} onClick={onClick}>
        {children}
      </Text>
    );
  if (variant === "h3")
    return (
      <Text style={{ fontSize: "18px", lineHeight: "21.6px", ...style }} onClick={onClick}>
        {children}
      </Text>
    );
  if (variant === "h2")
    return (
      <Text style={{ fontSize: "24px", lineHeight: "28.8px", ...style }} onClick={onClick}>
        {children}
      </Text>
    );
  if (variant === "h1")
    return (
      <Text style={{ fontSize: "32px", lineHeight: "38.4px", ...style }} onClick={onClick}>
        {children}
      </Text>
    );
  if (variant === "contentSmall")
    return (
      <Text style={{ fontSize: "10px", lineHeight: "12px", color: "#767D93", ...style }} onClick={onClick}>
        {children}
      </Text>
    );
  else
    return (
      <Text style={style} onClick={onClick}>
        {children}
      </Text>
    );
};

export default Typography;
