import React from "react";
import useBreakpoints from "../../hooks/useBreakpoints.ts";
const Instagram = () => {
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);
  return isMobile ? (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4 2.6665H21.6C25.8666 2.6665 29.3333 6.13317 29.3333 10.3998V21.5998C29.3333 23.6508 28.5185 25.6178 27.0683 27.0681C25.618 28.5184 23.651 29.3332 21.6 29.3332H10.4C6.13329 29.3332 2.66663 25.8665 2.66663 21.5998V10.3998C2.66663 8.34883 3.48139 6.38183 4.93167 4.93154C6.38195 3.48126 8.34895 2.6665 10.4 2.6665ZM10.1333 5.33317C8.86025 5.33317 7.63935 5.83888 6.73918 6.73906C5.83901 7.63923 5.33329 8.86013 5.33329 10.1332V21.8665C5.33329 24.5198 7.47996 26.6665 10.1333 26.6665H21.8666C23.1397 26.6665 24.3606 26.1608 25.2607 25.2606C26.1609 24.3604 26.6666 23.1395 26.6666 21.8665V10.1332C26.6666 7.47984 24.52 5.33317 21.8666 5.33317H10.1333ZM23 7.33317C23.442 7.33317 23.8659 7.50877 24.1785 7.82133C24.491 8.13389 24.6666 8.55781 24.6666 8.99984C24.6666 9.44186 24.491 9.86579 24.1785 10.1783C23.8659 10.4909 23.442 10.6665 23 10.6665C22.5579 10.6665 22.134 10.4909 21.8214 10.1783C21.5089 9.86579 21.3333 9.44186 21.3333 8.99984C21.3333 8.55781 21.5089 8.13389 21.8214 7.82133C22.134 7.50877 22.5579 7.33317 23 7.33317ZM16 9.33317C17.7681 9.33317 19.4638 10.0355 20.714 11.2858C21.9642 12.536 22.6666 14.2317 22.6666 15.9998C22.6666 17.7679 21.9642 19.4636 20.714 20.7139C19.4638 21.9641 17.7681 22.6665 16 22.6665C14.2318 22.6665 12.5362 21.9641 11.2859 20.7139C10.0357 19.4636 9.33329 17.7679 9.33329 15.9998C9.33329 14.2317 10.0357 12.536 11.2859 11.2858C12.5362 10.0355 14.2318 9.33317 16 9.33317ZM16 11.9998C14.9391 11.9998 13.9217 12.4213 13.1715 13.1714C12.4214 13.9216 12 14.939 12 15.9998C12 17.0607 12.4214 18.0781 13.1715 18.8283C13.9217 19.5784 14.9391 19.9998 16 19.9998C17.0608 19.9998 18.0782 19.5784 18.8284 18.8283C19.5785 18.0781 20 17.0607 20 15.9998C20 14.939 19.5785 13.9216 18.8284 13.1714C18.0782 12.4213 17.0608 11.9998 16 11.9998Z"
        fill="#FCFCFC"
      />
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.20004 1.3335H10.8C12.9334 1.3335 14.6667 3.06683 14.6667 5.20016V10.8002C14.6667 11.8257 14.2593 12.8092 13.5342 13.5343C12.809 14.2594 11.8255 14.6668 10.8 14.6668H5.20004C3.06671 14.6668 1.33337 12.9335 1.33337 10.8002V5.20016C1.33337 4.17466 1.74075 3.19116 2.46589 2.46602C3.19104 1.74088 4.17454 1.3335 5.20004 1.3335ZM5.06671 2.66683C4.43019 2.66683 3.81974 2.91969 3.36965 3.36977C2.91956 3.81986 2.66671 4.43031 2.66671 5.06683V10.9335C2.66671 12.2602 3.74004 13.3335 5.06671 13.3335H10.9334C11.5699 13.3335 12.1803 13.0806 12.6304 12.6306C13.0805 12.1805 13.3334 11.57 13.3334 10.9335V5.06683C13.3334 3.74016 12.26 2.66683 10.9334 2.66683H5.06671ZM11.5 3.66683C11.7211 3.66683 11.933 3.75463 12.0893 3.91091C12.2456 4.06719 12.3334 4.27915 12.3334 4.50016C12.3334 4.72118 12.2456 4.93314 12.0893 5.08942C11.933 5.2457 11.7211 5.3335 11.5 5.3335C11.279 5.3335 11.0671 5.2457 10.9108 5.08942C10.7545 4.93314 10.6667 4.72118 10.6667 4.50016C10.6667 4.27915 10.7545 4.06719 10.9108 3.91091C11.0671 3.75463 11.279 3.66683 11.5 3.66683ZM8.00004 4.66683C8.8841 4.66683 9.73194 5.01802 10.3571 5.64314C10.9822 6.26826 11.3334 7.11611 11.3334 8.00016C11.3334 8.88422 10.9822 9.73206 10.3571 10.3572C9.73194 10.9823 8.8841 11.3335 8.00004 11.3335C7.11599 11.3335 6.26814 10.9823 5.64302 10.3572C5.0179 9.73206 4.66671 8.88422 4.66671 8.00016C4.66671 7.11611 5.0179 6.26826 5.64302 5.64314C6.26814 5.01802 7.11599 4.66683 8.00004 4.66683ZM8.00004 6.00016C7.46961 6.00016 6.9609 6.21088 6.58583 6.58595C6.21075 6.96102 6.00004 7.46973 6.00004 8.00016C6.00004 8.5306 6.21075 9.0393 6.58583 9.41438C6.9609 9.78945 7.46961 10.0002 8.00004 10.0002C8.53047 10.0002 9.03918 9.78945 9.41425 9.41438C9.78933 9.0393 10 8.5306 10 8.00016C10 7.46973 9.78933 6.96102 9.41425 6.58595C9.03918 6.21088 8.53047 6.00016 8.00004 6.00016Z"
        fill="#FCFCFC"
      />
    </svg>
  );
};
export default Instagram;
