import React from "react";
import useBreakpoints from "../../hooks/useBreakpoints.ts";

const LinkedIn = () => {
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);

  return isMobile ? (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 3a3 3 0 1 1 0 6 3 3 0 1 1 0-6zm0 7h4v16H4V10zm9 0h4v2.44a4.42 4.42 0 0 1 3.98-2.44c4.42 0 5.02 2.9 5.02 6.7V26h-4v-8c0-2.8-.6-4-2.1-4a2.4 2.4 0 0 0-2.4 2.8V26h-4V10z"
        fill="#FCFCFC"
      />
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 1.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 1 1 0-3zM2 5h2v8H2V5zm4 0h2v1.22a2.2 2.2 0 0 1 1.99-1.22c2.2 0 2.51 1.45 2.51 3.35V13h-2V9c0-1.4-.3-2-.9-2a1.2 1.2 0 0 0-1.2 1.4V13H6V5z"
        fill="#FCFCFC"
      />
    </svg>
  );
};

export default LinkedIn;
