import { Row, Col, Flex, Divider } from "antd";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import Delivery from "../../components/Icons/Delivery.tsx";
import Material from "../../components/Icons/Material.tsx";
import Payment from "../../components/Icons/Payment.tsx";
import CartProductView from "../../components/cart/CartProductView.tsx";
import MainButton from "../../components/main/MainButton.tsx";
import { Currency, OrderItem, Product } from "../../services/shopService.ts";
import Typography from "../../components/typography/index.tsx";

const MobileOrderView = ({ orderCount, products, order }) => {
  const navigate = useNavigate();
  const findProduct = (orderItem: OrderItem): Product => {
    return products.find((p) => p.productId === orderItem.productId)!!;
  };

  return (
    <>
      <Col>
        <Row style={{ background: "#FCFCFC" }}>
          <Col style={{ padding: "16px" }}>
            <Typography variant="h3">
              {`Мій кошик (${orderCount.length})`.toUpperCase()}
            </Typography>
            <MainButton
              text={"Продовжити покупку".toUpperCase()}
              onClick={() => navigate(-1)}
              margin={"16px 0 0 0"}
            />
          </Col>
          {orderCount.map((item) => (
            <CartProductView
              product={products.find((p) => p.productId === item.productId)!!}
              order={order}
              orderItem={item}
            />
          ))}
        </Row>
        <Col style={{ padding: "16px", background: "#FFFFFF" }}>
          <Row justify={"space-between"}>
            <Typography variant="h3">{"TOTAL:"}</Typography>
            <Typography variant="h3" style={{ float: "right" }}>
              {orderCount.length > 0 ? Currency[products[0].currencyId] : ""}
              {orderCount.reduce(
                (sum, item) => sum + findProduct(item).price,
                0
              )}
            </Typography>
          </Row>
          <Row>
            <Divider style={{ background: "#D0D4DD" }} />
          </Row>
          <Row style={{ paddingBottom: "16px" }}>
            <Link to={"/checkout"}>
              <MainButton text={"Перейти до оформлення".toUpperCase()} />
            </Link>
          </Row>
          <Row style={{ paddingBottom: "48px", paddingTop: '16px' }}>
            <Flex vertical gap="16px">
              <Flex gap="12px">
                <div style={{ width: "33px", height: "33px" }}>
                  <Payment />
                </div>

                <Flex vertical gap="4px">
                  <Typography variant="h5">Оплата</Typography>
                  <Typography variant="contentSmall">
                    Кредитна картка, дебетова картка, PayPal або Apple Pay
                  </Typography>
                </Flex>
              </Flex>
              <Flex gap="12px">
                <div style={{ width: "33px", height: "33px" }}>
                  <Material />
                </div>
                <Flex vertical gap="4px">
                  <Typography variant="h5">Відправлення та доставка</Typography>
                  <Typography variant="contentSmall">
                    Вартість доставки залежить від компанії-перевізника
                  </Typography>
                </Flex>
              </Flex>
              <Flex gap="12px">
                <div style={{ width: "33px", height: "33px" }}>
                  <Delivery />
                </div>
                <Flex vertical gap="4px">
                  <Typography variant="h5">Повернення та обмін</Typography>
                  <Typography variant="contentSmall">Безкоштовно</Typography>
                </Flex>
              </Flex>
            </Flex>
          </Row>
        </Col>
      </Col>
    </>
  );
};

export default MobileOrderView;
