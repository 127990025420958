import { Col, Divider, Flex, Row } from "antd";
import * as React from "react";
import LogoIcon from "../Icons/LogoIcon.tsx";
import VisaIcon from "../Icons/VisaIcon.tsx";
import MasterCardIcon from "../Icons/MasterCardIcon.tsx";
import Instagram from "../Icons/Instagram.tsx";
import RectangleDivider from "../dividers/RectangleDivider.tsx";
import Facebook from "../Icons/Facebook.tsx";
import Youtube from "../Icons/Youtube.tsx";
import useBreakpoints from "../../hooks/useBreakpoints.ts";
import Mobile from "../Icons/Mobile.tsx";
import Email from "../Icons/Email.tsx";
import styledComponents from "styled-components";
import Typography from "../typography/index.tsx";
import EmailSubscription from "./EmailSubscription.tsx";
import { Link } from "react-router-dom";
import HeaderButton from "../header/HeaderButton.tsx";
import LinkedIn from "../Icons/LinkedIn.tsx";
import TikTok from "../Icons/TikTok.tsx";

export const FooterFlex = styledComponents((props) => <Flex {...props} />)`
      text-align: center;
      align-self: end;
      color: #fff;
      background-color: black;
      width: 100%;
      height: fit-content;
`;

const Footer = () => {
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);
  const isTablet = ["md"].includes(breakpoint);
  const isDesktop = ["xl"].includes(breakpoint);

  const [buttonStyle, setButtonStyle] = React.useState<any>()

  return (
    <FooterFlex vertical>
      <Flex
        vertical={isMobile}
        style={{ justifyContent: "space-between", padding: isDesktop ? "32px 40px" : "24px 24px 32px" }}
      >
        <Flex gap="middle" vertical style={isMobile ? {} : { width: isDesktop ? "30%" : "40%" }}>
          <Flex gap="middle" vertical={isMobile} style={{ alignItems: "center" }}>
            <LogoIcon fill="white" />
            <RectangleDivider />
            <Flex gap="middle" vertical={false} style={{ alignItems: "center" }}>
              <a href="https://www.instagram.com/madjo.ua">
                <Instagram />
              </a>
              <RectangleDivider />
              <a href="https://www.facebook.com/madjoofficial">
                <Facebook />
              </a>
              <RectangleDivider />
              <a href="https://www.linkedin.com/company/madjo-ua">
                <LinkedIn />
              </a>
              <RectangleDivider />
              <a href="https://www.tiktok.com/@madjo.ua">
                <TikTok />
              </a>
            </Flex>
          </Flex>
          {!isMobile ? <EmailSubscription /> : null}
        </Flex>
        <Flex
          gap="middle"
          vertical={isTablet || isMobile}
          style={
            isMobile ? { marginTop: "40px" } : { width: isDesktop ? "30%" : "40%", justifyContent: "space-between" }
          }
        >
          <Flex gap="middle" vertical style={{ textAlign: isMobile ? "center" : "left" }}>
            <Link to={'/men'}>
              <Typography variant="uppercase" style={{ color: 'white' }}>Чоловікам</Typography>
            </Link>
            <Link to={'/women'}>
              <Typography variant="uppercase" style={{ color: 'white' }}>Жінкам</Typography>
            </Link>
            <Link to={'/research'}>
              <Typography variant="uppercase" style={{ color: 'white' }}>Дослідження</Typography>
            </Link>
            <a href="https://t.me/madjo_ua" target="_blank" rel="noopener noreferrer">
              <Typography variant="uppercase" style={{ color: 'white' }}>Підтримка</Typography>
            </a>
          </Flex>
          <Flex gap="middle" vertical style={{ textAlign: isMobile ? "center" : "left" }}>
            <Typography variant="uppercase">Контакти</Typography>
            <Typography variant="underline">
              <Mobile />
              <span style={{ marginLeft: "8px" }}>+380 95 925 87 32</span>
            </Typography>
            <Typography variant="underline">
              <Email />
              <span style={{ marginLeft: "8px" }}>business@madjo.com.ua</span>
            </Typography>
          </Flex>
          {isMobile ? <EmailSubscription /> : null}
        </Flex>
      </Flex>
      <Flex vertical style={{ padding: isDesktop ? "0 32px 40px" : "0 24px 24px" }}>
        <Divider style={{ height: "1px", backgroundColor: "#767D93", margin: 0 }} />

        <Row style={{ rowGap: "24px", marginTop: "32px" }}>
          <Col xs={24} md={8} style={{ textAlign: isMobile ? "center" : "left" }}>
            <Typography variant="uppercase"> ©Madjo 2024 | All rights received </Typography>
          </Col>
          <Col xs={24} md={8}>
            <Flex style={{ gap: "24px", justifyContent: "center" }}>
              <VisaIcon />
              <MasterCardIcon />
            </Flex>
          </Col>

          <Col xs={24} md={8} style={{ textAlign: isMobile ? "center" : "right" }}>
            <Flex style={{ gap: "24px", justifyContent: isMobile ? "center" : "end" }}>
              <Link to={`privacy-policy`}>
                <Typography variant="uppercase" style={{ color: "white" }}>
                  Політика Конфіденційності
                </Typography>
              </Link>
              <Link to={'public-offer'}>
                <Typography variant="uppercase" style={{ color: "white" }}>Публічна Оферта</Typography>
              </Link>
            </Flex>
          </Col>
        </Row>
      </Flex>
    </FooterFlex>
  );
};

export default Footer;
