import { Form } from "antd";
import * as React from "react";
import { Department, shopService } from "../../services/shopService.ts";
import Typography from "../typography/index.tsx";
import { SelectStyled } from "../utils/utils.tsx";

interface DepartmentDeliveryFormInterface {
  city: string;
  department: string;
  setDepartment: (value: string) => void;
  deliveryEnabled: boolean;
}

const DepartmentDeliveryForm = ({
  city,
  department,
  setDepartment,
  deliveryEnabled,
}: DepartmentDeliveryFormInterface) => {
  const [departments, setDepartments] = React.useState<
    Department[] | undefined
  >([]);
  const [departmentLoading, setDepartmentLoading] = React.useState<boolean>();

  React.useEffect(() => {
    setDepartmentLoading(true);
    shopService
      .getDepartments({
        findByString: department,
        cityName: city,
        limit: 50,
        page: 0,
      })
      .then(setDepartments)
      .finally(() => setDepartmentLoading(false));
  }, [city, department]);

  return (
    <>
      <Typography style={{ textTransform: "uppercase" }} variant="h4">
        Відділення
      </Typography>
      <Form.Item
        name={"deliveryAddress"}
        style={{}}
        rules={[{ required: true, message: "Відсутнє відділення доставки" }]}
      >
        <SelectStyled
          showSearch
          disabled={!deliveryEnabled}
          value={department}
          optionFilterProp="label"
          options={departments?.map(({ description }) => ({
            value: description,
            label: description,
          }))}
          onChange={setDepartment}
          onSearch={setDepartment}
          loading={departmentLoading}
          style={{ borderRadius: 0,  width: "100%", maxWidth: 'calc(100vw - 32px)'  }}
          
        />
      </Form.Item>
    </>
  );
};

export default DepartmentDeliveryForm;
