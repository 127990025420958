import { Col, Flex, Image, Row, Segmented } from "antd";
import * as React from "react";
import styled from "styled-components";
import { Currency, Order, OrderItem, Product, Size, shopService } from "../../services/shopService.ts";
import DeleteButton from "../buttons/DeleteButton.tsx";
import Typography from "../typography/index.tsx";

interface CartProductViewInterface {
  product: Product;
  order: Order;
  orderItem: OrderItem;
}

const ProductViewContainer = styled.div`
  cursor: pointer;
  display: grid;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
`;

const CartProductView = ({ product, order, orderItem }: CartProductViewInterface) => {
  const [productPreview, setProductPreview] = React.useState<string>();
  const [imageHovered, setImageHovered] = React.useState(false);

  React.useEffect(() => {
    const link = product.productImages.find((productImage) =>
      imageHovered ? productImage.is3dModel : productImage.isPrimary
    )?.link;
    setProductPreview(link);
  }, [imageHovered, product.productImages]);

  return (
    productPreview && orderItem && (
      <>
        <Row style={{ padding: "0 16px 16px" }}>
          <Col span={8}>
            <ProductViewContainer onMouseOver={() => setImageHovered(true)} onMouseLeave={() => setImageHovered(false)}>
              <Image max-width={`78px`} src={productPreview} alt={product.description} preview={false} />
            </ProductViewContainer>
          </Col>
          <Col span={16} style={{ display: "grid", gridTemplateRows: "auto 1fr auto", paddingLeft: "16px" }}>
            <Flex vertical>
              <Typography variant="h3">{product.name}</Typography>
              <Typography variant="h2" style={{ margin: "24px 0  0" }}>
                {Currency[product.currencyId]}
                {product.price}
              </Typography>
            </Flex>
            <Flex align="center" gap="small">
              <Typography>Розмір:</Typography>
              <Segmented size="small" options={[Size[orderItem.sizeId]]} />
            </Flex>
            <Flex>
              <DeleteButton product={product} orderItems={order.orderItems}  />
            </Flex>
          </Col>
        </Row>
      </>
    )
  );
};

export default CartProductView;
