import { Divider, Flex, Form, Input } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";
import CheckboxGrey from "../../components/Checkbox/CheckboxGrey.tsx";
import CityDeliveryForm from "../../components/checkout/CityDeliveryForm.tsx";
import DepartmentDeliveryForm from "../../components/checkout/DepartmentDeliveryForm.tsx";
import MainButton from "../../components/main/MainButton.tsx";
import Typography from "../../components/typography/index.tsx";
import { InputStyled } from "../../components/utils/utils.tsx";

interface ContactFormInterface {
  price: number;
  onFinish: (values: any) => Promise<void>;
  data: string;
  signature: string;
  loading: boolean;
  city: string;
  setCity: (city: string) => void;
  department: string;
  setDepartment: (dpt: string) => void;
  isDeliveryActive: boolean;
  setDeliveryActive: (isActive: boolean) => void;
  isCallMeBack: boolean;
  setIsCallMeBack: (isActive: boolean) => void;
  isReadActive: boolean;
  setIsReadActive: (isActive: boolean) => void;
}

const MobileContactForm = ({
  price,
  onFinish,
  data,
  signature,
  loading,
  city,
  setCity,
  department,
  setDepartment,
  isDeliveryActive,
  setDeliveryActive,
  isCallMeBack,
  setIsCallMeBack,
  isReadActive,
  setIsReadActive,
}: ContactFormInterface) => {
  return (
    <>
      <Form onFinish={onFinish}>
        <input type="hidden" name="data" value={data} />
        <input type="hidden" name="signature" value={signature} />
        <Flex vertical gap={"16px"} style={{ padding: "0 16px" }}>
          <Typography style={{ textTransform: "uppercase" }} variant="h4">
            Контактні Дані
          </Typography>

          <Flex gap={"16px"} vertical>
            <Form.Item
              name={"surname"}
              style={{ width: "-webkit-fill-available" }}
              rules={[{ required: true, message: "Відсутнє прізвище" }]}
            >
              <InputStyled
                prefix={<></>}
                color="#767D93"
                fontSize="14px"
                placeholder="Прізвище*"
                style={{ borderRadius: 0 }}
              />
            </Form.Item>
            <Form.Item
              name={"name"}
              style={{ width: "-webkit-fill-available" }}
              rules={[{ required: true, message: "Відсутнє ім'я" }]}
            >
              <InputStyled
                prefix={<></>}
                color="#767D93"
                fontSize="14px"
                placeholder="Ім’я*"
                style={{ borderRadius: 0 }}
              />
            </Form.Item>
            <Form.Item
              name={"parentalName"}
              style={{ width: "-webkit-fill-available" }}
              rules={[{ required: false }]}
            >
              <InputStyled
                prefix={<></>}
                color="#767D93"
                fontSize="14px"
                placeholder="По батькові"
                style={{ borderRadius: 0 }}
              />
            </Form.Item>
            <Form.Item
              name={"phoneNumber"}
              style={{ width: "-webkit-fill-available" }}
              rules={[{ required: true, message: "Відсутній номер телефону" }]}
            >
              <InputStyled
                prefix={<></>}
                color="#767D93"
                fontSize="14px"
                placeholder="Номер телефону*"
                style={{ borderRadius: 0 }}
              />
            </Form.Item>
            <Flex vertical>
              <Form.Item
                name={"email"}
                rules={[
                  { required: true, message: "Відсутня електронна пошта" },
                ]}
              >
                <InputStyled
                  color={"#767D93"}
                  prefix={<></>}
                  fontSize="14px"
                  placeholder="E-mail"
                  style={{ borderRadius: 0 }}
                />
              </Form.Item>
            </Flex>
          </Flex>
          <CityDeliveryForm
            city={city}
            setCity={setCity}
            setActive={setDeliveryActive}
            isActive={isDeliveryActive}
          />
          <DepartmentDeliveryForm
            city={city}
            department={department}
            setDepartment={setDepartment}
            deliveryEnabled={isDeliveryActive}
          />
          <Divider style={{ background: "#D0D4DD" }} />
          <Form.Item name={"comment"} rules={[{ required: false }]}>
            <Input
              placeholder="Додати коментар до замовлення"
              style={{ borderRadius: 0 }}
            />
          </Form.Item>

          <Flex gap={"small"} onClick={() => setIsCallMeBack(!isCallMeBack)}>
            <CheckboxGrey isActive={isCallMeBack} />{" "}
            <Typography variant="uppercase" style={{ color: "#767D93" }}>
              Передзвоніть мені
            </Typography>
          </Flex>
          <Form.Item
            name={"checkbox"}
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  isReadActive
                    ? Promise.resolve()
                    : Promise.reject(new Error("Необхідно погодитися")),
              },
            ]}
          >
            <Flex gap={"small"} onClick={() => setIsReadActive(!isReadActive)}>
              <CheckboxGrey isActive={isReadActive} />{" "}
              <Typography variant="uppercase" style={{ color: "#767D93" }}>
                Я прочитав(ла){" "}
                <Link
                  to={"/privacy-policy"}
                  style={{ textDecorationLine: "underline", color: "#9FB3D6" }}
                >
                  умови та правила
                </Link>
                , та погоджуюсь з ними
              </Typography>
            </Flex>
          </Form.Item>

          <MainButton
            text={"Оформити замовлення".toUpperCase()}
            margin={"0 0 16px 0"}
            type={"submit"}
            alignButton="center"
            disabled={loading || price === 0}
          />
        </Flex>
      </Form>
    </>
  );
};

export default MobileContactForm;
