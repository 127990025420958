import React from "react";
export const NotFoundDesktop = () => (
  <svg
    width="708"
    height="373"
    viewBox="0 0 708 373"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M185.727 234.579H206.5V251.069H164.954V308H147.643V293.767H130.332V279.534V251.069H65.416H32.958H16.729V233.017H0.5V214.966L113.021 65H138.987H151.971V84.1363H164.954V103.273V141.545V218.09H185.727V234.579ZM34.0832 218.09H130.332V89.6472L34.0832 218.09Z"
      fill="#767D93"
    />
    <path
      d="M686.727 234.579H707.5V251.069H665.954V308H648.643V293.767H631.332V279.534V251.069H566.416H533.958H517.729V233.017H501.5V214.966L614.021 65H639.987H652.971V84.1363H665.954V103.273V141.545V218.09H686.727V234.579ZM535.083 218.09H631.332V89.6472L535.083 218.09Z"
      fill="#767D93"
    />
    <path
      d="M368.682 156.498H339.344V175.737H368.682V156.498Z"
      fill="#767D93"
    />
    <path
      d="M368.682 98.1344H339.344V117.373H368.682V98.1344Z"
      fill="#767D93"
    />
    <path d="M383.35 78.8953H354.012V98.1344H383.35V78.8953Z" fill="#767D93" />
    <path d="M398.02 59.681H368.682V78.9201H398.02V59.681Z" fill="#767D93" />
    <path d="M412.69 40.4419H383.352V59.681H412.69V40.4419Z" fill="#767D93" />
    <path d="M427.358 21.2027H398.02V40.4418H427.358V21.2027Z" fill="#767D93" />
    <path d="M339.319 156.498H309.98V175.737H339.319V156.498Z" fill="#767D93" />
    <path d="M312.59 156.498H283.252V175.737H312.59V156.498Z" fill="#767D93" />
    <path d="M398.02 156.498H368.682V175.737H398.02V156.498Z" fill="#767D93" />
    <path d="M427.358 156.498H398.02V175.737H427.358V156.498Z" fill="#767D93" />
    <path
      d="M442.028 139.173H412.689V158.412H442.028V139.173Z"
      fill="#767D93"
    />
    <path
      d="M295.311 139.173H265.973V158.412H295.311V139.173Z"
      fill="#767D93"
    />
    <path
      d="M456.698 119.959H427.359V139.198H456.698V119.959Z"
      fill="#767D93"
    />
    <path d="M470.62 100.719H441.281V119.959H470.62V100.719Z" fill="#767D93" />
    <path d="M485.29 81.4803H455.951V100.719H485.29V81.4803Z" fill="#767D93" />
    <path
      d="M499.958 62.2413H470.619V81.4804H499.958V62.2413Z"
      fill="#767D93"
    />
    <path
      d="M353.987 78.8953H324.648V98.1344H353.987V78.8953Z"
      fill="#767D93"
    />
    <path d="M339.319 59.681H309.98V78.9201H339.319V59.681Z" fill="#767D93" />
    <path d="M324.649 40.4419H295.311V59.681H324.649V40.4419Z" fill="#767D93" />
    <path
      d="M309.981 21.2027H280.643V40.4418H309.981V21.2027Z"
      fill="#767D93"
    />
    <path
      d="M280.641 119.959H251.303V139.198H280.641V119.959Z"
      fill="#767D93"
    />
    <path
      d="M266.717 100.719H237.379V119.959H266.717V100.719Z"
      fill="#767D93"
    />
    <path
      d="M252.049 81.4803H222.711V100.719H252.049V81.4803Z"
      fill="#767D93"
    />
    <path
      d="M237.379 62.2413H208.041V81.4804H237.379V62.2413Z"
      fill="#767D93"
    />
    <path d="M366.295 0H341.73V21.5508H366.295V0Z" fill="#767D93" />
    <path
      d="M368.682 197.263H339.344V216.502H368.682V197.263Z"
      fill="#767D93"
    />
    <path
      d="M368.682 255.627H339.344V274.866H368.682V255.627Z"
      fill="#767D93"
    />
    <path d="M383.35 274.866H354.012V294.105H383.35V274.866Z" fill="#767D93" />
    <path d="M398.02 294.105H368.682V313.344H398.02V294.105Z" fill="#767D93" />
    <path d="M412.69 313.344H383.352V332.583H412.69V313.344Z" fill="#767D93" />
    <path d="M427.358 332.558H398.02V351.797H427.358V332.558Z" fill="#767D93" />
    <path d="M339.319 197.263H309.98V216.502H339.319V197.263Z" fill="#767D93" />
    <path d="M312.59 197.263H283.252V216.502H312.59V197.263Z" fill="#767D93" />
    <path d="M398.02 197.263H368.682V216.502H398.02V197.263Z" fill="#767D93" />
    <path d="M427.358 197.263H398.02V216.502H427.358V197.263Z" fill="#767D93" />
    <path
      d="M442.028 214.588H412.689V233.827H442.028V214.588Z"
      fill="#767D93"
    />
    <path
      d="M295.311 214.588H265.973V233.827H295.311V214.588Z"
      fill="#767D93"
    />
    <path
      d="M456.698 233.827H427.359V253.066H456.698V233.827Z"
      fill="#767D93"
    />
    <path d="M470.62 253.042H441.281V272.281H470.62V253.042Z" fill="#767D93" />
    <path d="M485.29 272.281H455.951V291.52H485.29V272.281Z" fill="#767D93" />
    <path d="M499.958 291.52H470.619V310.759H499.958V291.52Z" fill="#767D93" />
    <path
      d="M353.987 274.866H324.648V294.105H353.987V274.866Z"
      fill="#767D93"
    />
    <path d="M339.319 294.105H309.98V313.344H339.319V294.105Z" fill="#767D93" />
    <path
      d="M324.649 313.344H295.311V332.583H324.649V313.344Z"
      fill="#767D93"
    />
    <path
      d="M309.981 332.558H280.643V351.797H309.981V332.558Z"
      fill="#767D93"
    />
    <path
      d="M280.641 233.827H251.303V253.066H280.641V233.827Z"
      fill="#767D93"
    />
    <path
      d="M266.717 253.042H237.379V272.281H266.717V253.042Z"
      fill="#767D93"
    />
    <path d="M252.049 272.281H222.711V291.52H252.049V272.281Z" fill="#767D93" />
    <path d="M237.379 291.52H208.041V310.759H237.379V291.52Z" fill="#767D93" />
    <path d="M366.295 351.449H341.73V373H366.295V351.449Z" fill="#767D93" />
    <path d="M501.5 176.01H476.936V197.561H501.5V176.01Z" fill="#767D93" />
    <path d="M231.065 176.01H206.5V197.561H231.065V176.01Z" fill="#767D93" />
    <path d="M366.295 175.737H341.73V197.288H366.295V175.737Z" fill="#767D93" />
  </svg>
);
