import * as React from "react";
import Typography from "../typography/index.tsx";
import { Flex, Form, Row, Select } from "antd";
import RadioButton from "../Icons/RadioButton.tsx";
import { City, shopService } from "../../services/shopService.ts";
import { SelectStyled } from "../utils/utils.tsx";

const CityDeliveryForm = ({ city, setCity, isActive, setActive }) => {
  const [cities, setCities] = React.useState<City[] | undefined>([]);
  const [cityLoading, setCityLoading] = React.useState<boolean>();

  React.useEffect(() => {
    setCityLoading(true);
    shopService
      .getCities({ findByString: city })
      .then(setCities)
      .finally(() => setCityLoading(false));
  }, [city]);

  const onCitiesChange = (e) => {
    setCity(e);
  };

  const onCitiesSearch = (e) => {
    setCity(e);
  };

  return (
    <>
      <Typography style={{ textTransform: "uppercase" }} variant="h4">
        Доставка
      </Typography>
      <Form.Item
        name={"city"}
        initialValue={city}
        rules={[{ required: true, message: "Відсутнє місто доставки" }]}
      >
        <SelectStyled
          showSearch
          value={city}
          options={cities?.map(({ description }) => ({
            value: description,
            label: description,
          }))}
          onChange={onCitiesChange}
          onSearch={onCitiesSearch}
          loading={cityLoading}
          
        />
      </Form.Item>
      <Flex onClick={() => setActive(!isActive)} gap={"4px"}>
        <RadioButton isActive={isActive} />
        <Typography variant="uppercase">Відділення Нової Пошти</Typography>
      </Flex>
    </>
  );
};

export default CityDeliveryForm;
