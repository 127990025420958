import * as React from "react";
import { Flex } from "antd";
import RectangleDivider from "../dividers/RectangleDivider.tsx";
import Bag from "./icons/Bag.tsx";
import Search from "./icons/Search.tsx";
import HeaderButton from "./HeaderButton.tsx";
import { useMediaQuery } from "react-responsive";
import HeaderMenu from "./icons/Menu.tsx";
import LogoIcon from "../Icons/LogoIcon.tsx";
import NavMobile from "./NavMobile.tsx";
import { Link } from "react-router-dom";
import { OrderContext } from "../../App.tsx";

const Header = () => {
  const isFullScreen = useMediaQuery({ query: "(min-width: 1440px)" });
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 769px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [open, setOpen] = React.useState(false);
  const scrollPos = React.useRef(0);
  const [visible, setVisible] = React.useState(true);
  const { order: orderCount } = React.useContext(OrderContext);

  const handleScroll = React.useCallback(() => {
    const currentScrollPos = window.scrollY - 150;
    console.log(currentScrollPos, scrollPos.current);

    if (currentScrollPos < 0 || currentScrollPos <= scrollPos.current) {
      setVisible(true);
    } else {
      
      setVisible(false);
    }
    const offset = isMobile ? 200 : 500;
    if (Math.abs(scrollPos.current - currentScrollPos) > offset)
      scrollPos.current = currentScrollPos;
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  React.useEffect(() => {
    setVisible(true);
    scrollPos.current = window.scrollY - 150;
  }, [orderCount]);

  return (
    <Flex
      style={{
        zIndex: "5",
        position: "sticky",
        top: 0,
        opacity: visible ? 1 : 0,
        transition: "ease-in-out",
      }}
      onMouseEnter={() => {
        setVisible(true);
        scrollPos.current = window.scrollY;
      }}
    >
      <Flex
        style={{
          padding: isFullScreen
            ? "16px 40px"
            : isDesktopOrLaptop
            ? "16px 24px"
            : "16px",
          backgroundColor: "white",
          borderBottom: "1px solid #D0D4DD",
          width: isMobile ? "calc(100vw - 32px)" : "100%",
          maxWidth: isMobile ? "calc(100vw - 32px)" : "",
          zIndex: isMobile ? "9999" : "5",
        }}
        justify="space-between"
      >
        <Flex gap="40px" align="center" justify="space-between">
          <LogoIcon fill="black" />
          <RectangleDivider />
          <Flex gap="32px">
            {isDesktopOrLaptop && (
              <>
                <Link to={"/men"}>
                  <HeaderButton text={"ЧОЛОВIКАМ"} />
                </Link>
                <Link to={"/women"}>
                  <HeaderButton text={"ЖIНКАМ"} />
                </Link>
                <Link to={"/autumn-2024"}>
                  <HeaderButton text={"ОСІНЬ 2024"} />
                </Link>
                <Link to={"/spring-2025"}>
                  <HeaderButton text={"ВЕСНА 2025"} />
                </Link>
                <Link to="/research">
                  <HeaderButton text={"Дослідження".toLocaleUpperCase()} />
                </Link>
              </>
            )}
          </Flex>
        </Flex>
        <Flex
          style={{ width: "159px" }}
          align="center"
          justify="flex-end"
          gap="16px"
        >
          <Bag />
          <RectangleDivider />
          {isDesktopOrLaptop && <Search />}
          {isMobile && (
            <div onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
              <HeaderMenu />
            </div>
          )}
        </Flex>
      </Flex>
      {isMobile && <NavMobile open={open && isMobile} setOpen={setOpen} />}
    </Flex>
  );
};

export default Header;
