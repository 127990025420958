import * as React from "react";
import loader from "./resources/Lottie.gif";

const Loading = ({ size }) => {
  return (
    <>
      <img
        src={loader}
        style={{ maxWidth: size, maxHeight: size, paddingTop: "15%" }}
      />
    </>
  );
};

export default Loading;
