import { Image } from "antd";
import * as React from "react";
import styled from "styled-components";
import Typography from "../../components/typography/index.tsx";
import { Currency, Product } from "../../services/shopService.ts";

interface ProductPreviewInterface {
  product: Product;
}

const ProductPreviewContainer = styled.div`
  cursor: pointer;
  padding: 8px 4px;
  display: grid;
  height: fit-content;
  box-sizing: border-box;
  align-items: center;
  >div {
    align-self: self-start;
  }
`;

const ProductPreview = ({ product }: ProductPreviewInterface) => {
  const [productPreview, setProductPreview] = React.useState<string>();
  const [imageHovered, setImageHovered] = React.useState(false);

  React.useEffect(() => {
    const link = product.productImages.find((productImage) =>
      imageHovered ? productImage.is3dModel : productImage.isPrimary
    )?.link;
    setProductPreview(link);
  }, [imageHovered, product.productImages]);

  return (
    productPreview && (
      <ProductPreviewContainer onMouseOver={() => setImageHovered(true)} onMouseLeave={() => setImageHovered(false)}>
        <Image max-width={`auto`} src={productPreview} alt={product.description} preview={false} />
        <div style={{ alignSelf: "end", flexDirection: "column", display: "flex", gap: "6px", paddingTop: "6px" }}>
          <Typography variant="h5" style={{ textTransform: 'uppercase' }}>{product.name}</Typography>
          <Typography variant="h5" style={{ color: "#767D93" }}>
            {Currency[product.currencyId]}{product.price}
          </Typography>
        </div>
      </ProductPreviewContainer>
    )
  );
};

export default ProductPreview;
