import { Flex, Image } from "antd";
import React from "react";
import Typography from "../../components/typography/index.tsx";
import { Research, shopService } from "../../services/shopService.ts";
import MadjoIcon from "../../components/Icons/MadjoIcon.tsx";
import { useLoaderData } from "react-router-dom";
import parse from "html-react-parser";
import useBreakpoints from "../../hooks/useBreakpoints.ts";

export const researchLoader = async ({ params }): Promise<Research | undefined> => {
  const research = await shopService.getResearch(params.researchId);
  return research;
};

const ResearchDescription = () => {
  const research = useLoaderData() as Research;

  return (
    <Flex vertical style={{ justifySelf: "center", margin: "80px 16px", maxWidth: "800px" }} justify="center" gap="24px">
      <Flex vertical gap="40px" style={{ justifyContent: "center", alignItems: "center" }}>
        <Image src={research.imageLink} preview={false} />
        <Typography variant="h1" style={{ textTransform: "uppercase" }}>
          {research.name}
        </Typography>
        <Flex justify="center" gap="12px">
          {Array.from({ length: 3 }, (_, index) => (
            <MadjoIcon key={index} />
          ))}
        </Flex>
        <Typography variant="h4" style={{ color: "#767D93", lineHeight: "28.8px" }}>
          {parse(research.description)}
        </Typography>
      </Flex>
    </Flex>
  );
};

export default ResearchDescription;
