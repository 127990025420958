import React from "react";
import "./App.css";

import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import CookieProvider from "./providers/CookieProvider.tsx";
import { customRouter } from "./services/router.tsx";
import { Order, OrderItem, shopService } from "./services/shopService.ts";

export const OrderContext = React.createContext<any>(null);
export const ProductSubscription = React.createContext<any>(null);
export const ProductSearch = React.createContext<any>(null);

const App: React.FC = () => {
  const [order, setOrder] = React.useState<OrderItem[]>([]);
  const [productSubscription, setProductSubscription] = React.useState(null);
  const [search, setSearch] = React.useState("");

  const addToCart = (item: Order) => {
    setOrder((prevState) => [...prevState, item.orderItems[0]]);
  };
  const same = (item, orderItem) =>
    item.orderItemId == orderItem.orderItemId &&
    item.productId == orderItem.productId &&
    item.quantity == orderItem.quantity &&
    item.sizeId == orderItem.sizeId;

  const removeFromCart = (orderItem: OrderItem) => {
    setOrder((prevState) => {
      return prevState.filter((item) => !same(item, orderItem));
    });
  };

  React.useEffect(() => {
    const orderId = localStorage.getItem("orderId");
    if (orderId)
      shopService.getOrder(+orderId).then((item) => {
        if (item) setOrder(item?.orderItems);
      });
  }, []);

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          components: {
            Segmented: {
              trackBg: "#fff",
              itemSelectedColor: "#767D93",
              itemActiveBg: "#FCFCFC",
              fontFamily: "Namu",
              fontSize: 12,
              borderRadiusXS: 0,
              boxShadowTertiary: "0px 0px 0px 1px #D0D4DD",
            },
            Button: {
              textHoverBg: "#fff",
            },
          },

          token: {},
        }}
      >
        <OrderContext.Provider value={{ order, addToCart, removeFromCart }}>
          <ProductSubscription.Provider
            value={{ productSubscription, setProductSubscription }}
          >
            <ProductSearch.Provider value={{ search, setSearch }}>
              <RouterProvider router={customRouter} />
            </ProductSearch.Provider>
          </ProductSubscription.Provider>
        </OrderContext.Provider>
      </ConfigProvider>
    </div>
  );
};

export default App;
