import { Col, Divider, Row } from "antd";
import * as React from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { OrderContext } from "../../App.tsx";
import CartProductPreview from "../../components/cart/preview/CartProductPreview.tsx";
import ContactForm from "../../components/checkout/ContactForm.tsx";
import MainButton from "../../components/main/MainButton.tsx";
import useBreakpoints from "../../hooks/useBreakpoints.ts";
import {
  Currency,
  Order,
  OrderItem,
  Product,
  shopService,
} from "../../services/shopService.ts";
import MobileCheckoutView from "../mobile/MobileCheckoutView.tsx";

interface CheckoutViewInterface {
  order: Order;
  products: Product[];
}

export const checkoutLoader = async (): Promise<
  CheckoutViewInterface | undefined
> => {
  const orderId = localStorage.getItem("orderId");
  if (!orderId) return;
  const order = await shopService.getOrder(+orderId);
  if (!order) return;
  const promises = order.orderItems.map((item) =>
    shopService.getProduct(item.productId)
  );
  const products = await Promise.all(promises);
  return { order, products: products.filter((p) => !!p) as Product[] };
};

const CheckoutView = () => {
  const { order, products } = useLoaderData() as CheckoutViewInterface;
  const navigate = useNavigate();
  const [shipping, setShipping] = React.useState(0);
  const { order: orderItems } = React.useContext(OrderContext);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);
  const isTablet = ["md"].includes(breakpoint);

  const findProduct = (orderItem: OrderItem): Product => {
    return products.find((p) => p.productId === orderItem.productId)!!;
  };

  React.useEffect(() => {
    setTotalPrice(
      orderItems.reduce((sum, item) => sum + findProduct(item).price, 0) +
        shipping
    );
  }, [orderItems]);

  React.useEffect(() => {
    if (orderItems.length === 0) setTotalPrice(0);
  }, [orderItems]);

  if (isMobile)
    return (
      <MobileCheckoutView
        products={products}
        orderItems={orderItems}
        order={order}
        totalPrice={totalPrice}
      />
    );
  return (
    <>
      <Row>
        <Col span={12} style={{ background: "#FCFCFC" }}>
          <Row
            justify={"space-between"}
            align={"middle"}
            style={{ padding: "16px" }}
          >
            <p style={{ fontSize: "18px", margin: 0 }}>
              {`Оформлення замовлення`.toUpperCase()}
            </p>
            <MainButton
              text={"Продовжити покупку".toUpperCase()}
              onClick={() => navigate(-2)}
            />
          </Row>
          <ContactForm price={totalPrice} />
        </Col>
        <Col span={12} style={{ padding: "16px", background: "#FFFFFF" }}>
          {orderItems.map((item) => (
            <CartProductPreview
              product={products.find((p) => p.productId === item.productId)!!}
              orderItems={order.orderItems}
              spans={isTablet ? [6, 18] : isMobile ? [8, 16] : [4, 20]}
            />
          ))}
          <Row justify={"space-between"} style={{ paddingBottom: "16px" }}>
            <p style={{ fontSize: "14px", margin: 0 }}>
              {"ПРОМІЖНИЙ ПІДСУМОК:"}
            </p>
            <p style={{ fontSize: "14px", margin: 0, float: "right" }}>
              {products.length > 0 ? Currency[products[0].currencyId] : ""}
              {orderItems.reduce(
                (sum, item) => sum + findProduct(item).price,
                0
              )}
            </p>
          </Row>
          <Row justify={"space-between"}>
            <p style={{ fontSize: "14px", margin: 0 }}>{"ДОСТАВКА:"}</p>
            <p style={{ fontSize: "14px", margin: 0, float: "right" }}>
              {"За тарифами перевізника".toUpperCase()}
            </p>
          </Row>
          <Row>
            <Divider style={{ background: "#D0D4DD" }} />
          </Row>
          <Row style={{ paddingBottom: "16px" }} justify={"space-between"}>
            <p style={{ fontSize: "14px", margin: 0 }}>
              {"підсумок:".toUpperCase()}
            </p>
            <p style={{ fontSize: "14px", margin: 0, float: "right" }}>
              {products.length > 0 ? Currency[products[0].currencyId] : ""}
              {totalPrice}
            </p>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CheckoutView;
