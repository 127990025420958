import React from "react";
const Email = () => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V9C0 9.26522 0.105357 9.51957 0.292893 9.70711C0.48043 9.89464 0.734784 10 1 10H13C13.2652 10 13.5196 9.89464 13.7071 9.70711C13.8946 9.51957 14 9.26522 14 9V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0ZM11.9 1L7 4.39L2.1 1H11.9ZM1 9V1.455L6.715 5.41C6.7987 5.46806 6.89813 5.49918 7 5.49918C7.10187 5.49918 7.2013 5.46806 7.285 5.41L13 1.455V9H1Z"
        fill="#9FB3D6"
      />
    </svg>
  );
};
export default Email;
